<template>
  <div class="pagination-desktop">
    <div class="pagination-desktop-total">
      {{ currentPageRangeDesktop }} из {{ props.totalItemsCount }}
      {{
        pluralize(props.totalItemsCount, [
          'инвестиции',
          'инвестиций',
          'инвестиций',
        ])
      }}
    </div>
    <div class="footer-pagination">
      <div>
        <button
          v-if="isShowPrevButton"
          class="button-page button-active"
          type="button"
          style="margin-right: 5px"
          @click="onClickPrevPage()"
        >
          Назад
        </button>
      </div>
      <div class="footer-pagination-buttons">
        <button
          v-for="(btn, index) in buttonPages"
          :key="index"
          class="button-page"
          :class="{
            'button-active': props.currentPage == btn.action,
          }"
          type="button"
          @click="onClickSelectedPageDesktop(btn.action)"
        >
          {{ btn.title }}
        </button>
      </div>
      <div>
        <button
          v-if="isShowNextButton"
          class="button-page button-active"
          type="button"
          style="margin-left: 5px"
          @click="onClickNextPage()"
        >
          Вперед
        </button>
      </div>
    </div>
    <div>
      <CSelect
        v-model="localSelectedPageRange"
        :items="[
          { value: 20, title: 'Показывать по 20 строк' },
          { value: 40, title: 'Показывать по 40 строк' },
        ]"
      >
      </CSelect>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { pluralize } from '@/utils/commonUtils/libUtils';
import CreatePageRange from './createPageRange';
import { CSelect } from 'potok-uikit';

const SIBLING_COUNT = 2;

const props = defineProps({
  totalItemsCount: {},
  currentPage: {},
  selectedPageRange: {},
});
const emit = defineEmits(['update:currentPage', 'update:selectedPageRange']);

const localSelectedPageRange = ref(null);

watch(
  () => {
    return localSelectedPageRange.value;
  },
  () => {
    emit('update:selectedPageRange', localSelectedPageRange.value);
  },
);
onMounted(() => {
  localSelectedPageRange.value = props.selectedPageRange;
});

const firstPageRangeDesktop = computed(() => {
  return (props.currentPage - 1) * props.selectedPageRange;
});
const lastPageRangeDesktop = computed(() => {
  return firstPageRangeDesktop.value + parseInt(props.selectedPageRange);
});
const currentPageRangeDesktop = computed(() => {
  let firstPage = firstPageRangeDesktop.value;
  if (firstPage === 0) {
    firstPage = 1;
  }
  let lastPage = lastPageRangeDesktop.value;
  if (props.totalItemsCount < lastPageRangeDesktop.value) {
    lastPage = props.totalItemsCount;
  }
  return `${firstPage}-${lastPage}`;
});
const totalPageCount = computed(() => {
  return Math.ceil(props.totalItemsCount / props.selectedPageRange);
});
const isShowPrevButton = computed(() => {
  if (props.currentPage > 1) {
    return true;
  }
  return false;
});
const isShowNextButton = computed(() => {
  if (props.currentPage < totalPageCount.value && totalPageCount.value > 1) {
    return true;
  }
  return false;
});
const buttonPages = computed(() => {
  return CreatePageRange({
    SIBLING_COUNT,
    totalPageCount: totalPageCount.value,
    currentPage: props.currentPage,
  });
});

const onClickSelectedPageDesktop = (value) => {
  let pageNumber = value;
  if (value === 'nextPageBlock') {
    pageNumber = props.currentPage + SIBLING_COUNT + 1;
  }
  if (value === 'prevPageBlock') {
    pageNumber = props.currentPage - SIBLING_COUNT - 1;
  }
  emit('update:currentPage', pageNumber);
};
const onClickPrevPage = () => {
  if (props.currentPage - 1 >= 0) {
    emit('update:currentPage', props.currentPage - 1);
  }
};
const onClickNextPage = () => {
  if (props.currentPage + 1 <= totalPageCount.value) {
    emit('update:currentPage', props.currentPage + 1);
  }
};
</script>
<style lang="scss" scoped>
.pagination-desktop {
  display: flex;
  flex-wrap: wrap;
  padding: 26px 0;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  color: $grey;
  font-weight: 400;
  select {
    font-size: 14px;
    color: black;
    height: 36px;
    min-width: 220px;
  }
  .footer-pagination {
    display: flex;
    flex-wrap: wrap;
    .footer-pagination-buttons {
      display: flex;
      flex-wrap: wrap;
    }
    .button-page {
      padding: 10px;
      color: black;
      height: 36px;
      border: none;
      font: inherit;
      cursor: pointer;
      background-color: transparent;
      display: flex;
      align-items: center;
    }
    .button-active {
      background: #ebedf4;
      border-radius: 6px;
    }
  }
}
</style>
