<template>
  <div>
    <div class="row" style="margin: 10px 0">
      <div class="col-md-12 block_content content_first">
        <div class="col-md-9 col-xs-12" style="padding: 0">
          <div class="subtitle">Индивидуальный отчет</div>
          <div>
            <div>
              <p class="block_description" style="max-width: 720px">
                Подробная статистика по вашему портфелю доступна в формате
                индивидуального отчета в формате Excel. Данные обновляются
                ежемесячно.
              </p>
              <div v-if="loadingCompaniesAccountStatementExcel">
                <p class="text-warning">
                  Формирование выписки может длиться несколько минут.
                  <br />Мы вышлем её на почту, указанную в вашем
                  <router-link :to="{ name: 'settings' }">профиле</router-link>,
                  как только она будет готова.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-xs-12" style="padding: 0">
          <div style="text-align: right">
            <button
              :disabled="loadingCompaniesAccountStatementExcel"
              class="button button-secondary-outline"
              type="button"
              @click="onClickGetIndividualStatement()"
            >
              Выслать отчет на почту
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row content_second">
      <div class="block_content">
        <div class="subtitle">График платежей</div>
        <p class="block_description" style="max-width: 726px">
          Здесь представлен актуальный на сегодняшний день график будущих
          платежей по вашим
          <router-link
            :to="{
              name: 'investments',
            }"
            target="_blank"
            >Инвестициям</router-link
          >
          – без учета возможных просрочек и возвратов по реструктурированным и
          дефолтным займам
        </p>
      </div>
    </div>
    <div class="row" style="margin-top: 30px">
      <div class="select_mode__wrapper col-md-12">
        <Switcher
          v-if="!message || message !== 'Отчет готовится'"
          v-model="selectedPeriodSchedulePayment"
          :items="periods"
          @update:model-value="updatePeriod"
        ></Switcher>

        <button
          v-if="!loadingPaymentSchedule && !message"
          type="button"
          class="potok-text-body-1 potok-color-deepdive select_mode__button"
          @click="onClickSelectMode"
        >
          <span v-if="isShowTableMode">показать в виде графика</span>
          <span v-if="!isShowTableMode">показать в виде таблицы</span>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p v-if="message" class="block_description" style="margin-top: 20px">
          {{ message }}
        </p>
        <button
          v-if="message && message === 'Отчет готовится'"
          class="button button-secondary"
          type="button"
          @click="onClickUpdatePaymentSchedule()"
        >
          Обновить статус отчета
        </button>
      </div>
      <div v-if="isShowTable" class="col-md-12" style="margin-top: 20px">
        <GeneralTable
          :items="filteredItems"
          :columns="columns"
          :total-columns="totalColumns"
        />
      </div>
      <div v-if="isShowGraph" class="col-md-12">
        <AnalyticsGraphPayment :items="filteredItems" />
      </div>
      <template v-if="loadingPaymentSchedule">
        <div class="col-md-12" style="margin-top: 20px">
          <Loader />
        </div>
      </template>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import ru from 'date-fns/locale/ru';
import { set, format, add, setMonth, setWeek } from 'date-fns';
import { useStateStore } from '@/store/stateStore';
import { formatCurrency } from '@/utils/commonUtils/utils';
import server from '@/server';
import { Loader } from 'potok-uikit';
import Switcher from '@/components/_generic/cselect/Switcher.vue';
import GeneralTable from '@/components/_generic/table/GeneralTable.vue';
import AnalyticsGraphPayment from '@/components/Analytics/AnalyticsMyPortfolio/AnalyticsGraphPayment.vue';
import tracker from '@/tracker';

const store = useStateStore();
const loadingCompaniesAccountStatementExcel = ref(false);
const loadingPaymentSchedule = ref(false);
const selectedPeriodSchedulePayment = ref('week');
const periods = ref([
  {
    title: 'По месяцам',
    value: 'month',
  },
  {
    title: 'По неделям',
    value: 'week',
  },
]);
const message = ref(null);
const paymentSchedule = ref([]);
const paymentScheduleTotal = ref({});
const columns = ref([
  {
    title: 'период',
    is_mobile_full: true,
    td_desktop_style: {
      'text-transform': 'capitalize',
    },
    content_desktop(item) {
      return item.date;
    },
  },
  {
    title: 'возврат основного долга',
    total_title_mobile: 'Возврат осн. долга',
    slug: 'mainDebt',
    is_mobile_full: true,
    th_desktop_style: {
      'text-align': 'left',
      'justify-content': 'flex-start',
    },
    td_desktop_style: {
      'text-align': 'left',
    },
    content_desktop(item) {
      return formatCurrency(item.mainDebt, 0) + ' ₽';
    },
  },
  {
    title: 'процентный доход',
    slug: 'percentage',
    is_mobile_full: true,
    th_desktop_style: {
      'text-align': 'left',
      'justify-content': 'flex-start',
    },
    td_desktop_style: {
      'text-align': 'left',
    },
    content_desktop(item) {
      return formatCurrency(item.percentage, 0) + ' ₽';
    },
  },
  {
    title: 'всего',
    slug: 'total',
    is_mobile_full: true,
    th_desktop_style: {
      'text-align': 'left',
      'justify-content': 'flex-start',
    },
    td_desktop_style: {
      'text-align': 'left',
    },
    content_desktop(item) {
      return formatCurrency(item.total, 0) + ' ₽';
    },
  },
]);
const isShowTableMode = ref(true);

const isUserRegistrated = computed(() => {
  return store.isUserRegistrated;
});
const selectedCompany = computed(() => {
  return store.selectedCompany;
});
const filteredItems = computed(() => {
  if (!paymentSchedule.value || paymentSchedule.value.length == 0) {
    return [];
  }
  return paymentSchedule.value.map((item) => {
    let date;
    if (selectedPeriodSchedulePayment.value === 'week') {
      const newDay = set(new Date(), {
        year: item.year,
        month: 0,
        date: 1,
        hours: 1,
        minutes: 1,
        seconds: 1,
      });
      const dateByWeek = add(setWeek(newDay, item.week, { weekStartsOn: 1 }), {
        days: 1,
      });
      date = format(dateByWeek, 'dd.MM.yyyy');
      date += ' - ' + format(add(dateByWeek, { days: 6 }), 'dd.MM.yyyy');
    }
    if (selectedPeriodSchedulePayment.value === 'month') {
      const newDay = set(new Date(), {
        year: item.year,
        month: 0,
        date: 1,
        hours: 1,
        minutes: 1,
        seconds: 1,
      });
      const dateByMonth = setMonth(newDay, item.month - 1);
      date = format(dateByMonth, 'LLLL yyyy', { locale: ru });
    }
    return {
      date: date,
      mainDebt: item.mainDebt,
      percentage: item.percentage,
      total: item.mainDebt + item.percentage,
    };
  });
});
const totalColumns = computed(() => {
  return {
    mobileTitle: 'Всего по будущим платежам',
    mainDebt: formatCurrency(paymentScheduleTotal.value.mainDebt, 0) + ' ₽',
    percentage: formatCurrency(paymentScheduleTotal.value.percentage, 0) + ' ₽',
    total: formatCurrency(paymentScheduleTotal.value.total, 0) + ' ₽',
  };
});

const isShowTable = computed(() => {
  return (
    isShowTableMode.value &&
    !loadingPaymentSchedule.value &&
    filteredItems.value.length > 0
  );
});
const isShowGraph = computed(() => {
  return (
    !isShowTableMode.value &&
    !loadingPaymentSchedule.value &&
    filteredItems.value.length > 0
  );
});

watch(
  () => {
    return selectedCompany.value;
  },
  () => {
    if (!selectedCompany.value) {
      return;
    }
    postCompanySchedulePayment();
  },
);
onMounted(() => {
  if (!isUserRegistrated.value) {
    return;
  }
  postCompanySchedulePayment();
});

const onClickGetIndividualStatement = () => {
  store.pushConfirm({
    message: `отчет будет отправлен на ваш email адрес.`,
    action_text: 'отправить',
    callback: () => {
      getIndividualStatement();
      store.removeConfirm();
    },
  });
};
const getIndividualStatement = () => {
  return server.getIndividualStatement
    .send({ companyId: selectedCompany.value.id })
    .pipe(onGetIndividualStatementSuccess)
    .exec();
};
const onGetIndividualStatementSuccess = () => {
  tracker.queue(tracker.commands.SEND, 'Investor_LK_Investor_Report_Order');
};
const postCompanySchedulePayment = () => {
  message.value = null;
  paymentSchedule.value = [];
  paymentScheduleTotal.value = {};
  loadingPaymentSchedule.value = true;
  const query = {
    period: selectedPeriodSchedulePayment.value,
  };
  return server.postCompanySchedulePayment
    .send(query, { params: { companyId: selectedCompany.value.id } })
    .pipe(onPostCompanySchedulePaymentSuccess, () => {
      loadingPaymentSchedule.value = false;
    })
    .exec();
};
const onPostCompanySchedulePaymentSuccess = (obj) => {
  loadingPaymentSchedule.value = false;
  const data = obj.data || {};
  if (data.message) {
    message.value = data.message;
  }
  if (data.isSuccessful) {
    paymentSchedule.value = data.paymentSchedule;
    paymentScheduleTotal.value.mainDebt = data.total.mainDebt;
    paymentScheduleTotal.value.percentage = data.total.percentage;
    paymentScheduleTotal.value.total =
      data.total.mainDebt + data.total.percentage;
  }
};
const updatePeriod = () => {
  postCompanySchedulePayment();
};
const onClickUpdatePaymentSchedule = () => {
  postCompanySchedulePayment();
};
const onClickSelectMode = () => {
  isShowTableMode.value = !isShowTableMode.value;
};
</script>
<style lang="scss" scoped>
.content_first {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.content_second {
  margin-top: 30px;
  margin-left: 0;
  margin-right: 0;
  @media (max-width: $size_767) {
    margin-top: 29px;
  }
}

.select_mode__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}
.select_mode__button {
  background: transparent;
  border: 0;
  @media (max-width: $size_767) {
    margin: 20px 0 0 0;
  }
}
.subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: $deep_dive;
  margin-bottom: 10px;
  @media (max-width: $size_767) {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }
}
.button {
  @media (max-width: $size_767) {
    width: 100%;
    margin: 15px 0;
    @media (max-width: $size_767) {
      margin-bottom: 4px;
    }
  }
}

:deep(.tableitems__table) {
  td {
    padding: 10px 20px;
  }
  th {
    padding: 20px 20px 13px 20px;
  }
}
</style>
