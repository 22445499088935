<template>
  <section class="page qualification_wrapper">
    <div class="qualification-title potok-text-h1">Квалификация инвестора</div>
    <div>
      <div class="row mt-3">
        <div class="col-md-10 col-xs-12">
          <div class="qualification-subtitle">
            чтобы подтвердить квалификацию, выберите подходящие условия и
            загрузите документ
          </div>
        </div>
        <div class="col-md-2 col-xs-12">
          <PotokButton
            text="зачем это нужно?"
            theme="linkIconUpload"
            no-full-width-tablet
            size="link"
            class="button-help"
            @click="isShowHelpMessage = true"
          />
        </div>
      </div>
      <div class="row content-wrapper">
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="qualification-checkboxes">
            <div class="checkbox-row">
              <input
                id="hasQiEducation"
                v-model="form.hasQiEducation"
                type="checkbox"
                class="checkbox-hidden"
              />
              <label for="hasQiEducation">
                <div class="label-items">
                  <span class="qualification qualification-teacher"> </span>
                  <span class="check"></span>
                </div>
                <div class="label-text-wrapper">
                  <span class="label-text">
                    высшее эконом. образование в ВУЗе с правом аттестации
                    профучастника
                  </span>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="qualification-checkboxes">
            <div class="checkbox-row">
              <input
                id="hasQiSecurities"
                v-model="form.hasQiSecurities"
                type="checkbox"
                class="checkbox-hidden"
              />
              <label for="hasQiSecurities">
                <div class="label-items">
                  <span class="qualification qualification-status-up"> </span>
                  <span class="check"></span>
                </div>
                <div class="label-text-wrapper">
                  <span class="label-text">
                    общая стоимость ценных бумаг и производных > 6 млн ₽
                  </span>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="qualification-checkboxes">
            <div class="checkbox-row">
              <input
                id="hasQiExperience"
                v-model="form.hasQiExperience"
                type="checkbox"
                class="checkbox-hidden"
              />
              <label for="hasQiExperience">
                <div class="label-items">
                  <span class="qualification qualification-clock"> </span>
                  <span class="check"></span>
                </div>
                <div class="label-text-wrapper">
                  <span class="label-text">
                    опыт работы в финансовой компании > 2 лет
                  </span>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="qualification-checkboxes">
            <div class="checkbox-row">
              <input
                id="hasQiProperty"
                v-model="form.hasQiProperty"
                type="checkbox"
                class="checkbox-hidden"
              />
              <label for="hasQiProperty">
                <div class="label-items">
                  <span class="qualification qualification-lock"> </span>
                  <span class="check"></span>
                </div>
                <div class="label-text-wrapper">
                  <span class="label-text">
                    депозитные, металлические счета > 6 млн ₽
                  </span>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="qualification-checkboxes">
            <div class="checkbox-row">
              <input
                id="hasQiSecuritiesDeals"
                v-model="form.hasQiSecuritiesDeals"
                type="checkbox"
                class="checkbox-hidden"
              />
              <label for="hasQiSecuritiesDeals">
                <div class="label-items">
                  <span class="qualification qualification-shopping-cart">
                  </span>
                  <span class="check"></span>
                </div>
                <div class="label-text-wrapper">
                  <span class="label-text">
                    активная торговля ценными бумагами и производными на сумму >
                    6 млн ₽
                  </span>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="qualification-checkboxes">
            <div class="checkbox-row">
              <input
                id="hasQiBroker"
                v-model="form.hasQiBroker"
                type="checkbox"
                class="checkbox-hidden"
              />
              <label for="hasQiBroker">
                <div class="label-items">
                  <span class="qualification qualification-broker"> </span>
                  <span class="check"></span>
                </div>
                <div class="label-text-wrapper">
                  <span class="label-text"> выписка от биржевого брокера </span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 qualification-files-wrapper">
          <div>
            <div v-if="documents.length < 15">
              <div class="qualification-files-add">
                <PotokButton
                  :disabled="loading"
                  theme="linkIconUpload"
                  size="link"
                  no-full-width-tablet
                  :text="
                    documents.length < 1
                      ? 'загрузить документ'
                      : 'добавить ещё документ'
                  "
                  class="qualification-files-add-text"
                  @click="addDocument"
                >
                  <template #icon>
                    <PotokImg
                      :src="imageConstants.qualificationFilesAdd"
                      class="mr-2"
                    />
                  </template>
                </PotokButton>
                <QuestionPopover>
                  Разрешены файлы с расширением jpg, jpeg, png, gif, heic или
                  pdf размером не более 7 Мб
                </QuestionPopover>
              </div>
            </div>
            <div
              class="qualification-files"
              :class="{ 'mt-5': documents.length }"
            >
              <input
                v-if="isShowInputElement"
                id="fileInput"
                type="file"
                style="display: none"
                accept=".jpg,.png,.pdf,.heic"
                @change="addDocumentFile"
              />
              <template v-for="(item, index) in documents" :key="index">
                <QualificationDocumentElement
                  :item="item"
                  :index-item="index"
                  @remove-document="removeDocument"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <QualificationModalSMSConfirm
      v-if="isShowModalTwoFactorAuth"
      :form="form"
      :documents="documents"
      @close="onCloseModalTwoFactorAuth"
    >
    </QualificationModalSMSConfirm>
    <div class="row mt-7">
      <div class="col-md-12" style="margin-bottom: 20px">
        <div class="two-factor-container">
          <PotokButton
            :disabled="loading"
            size="large"
            text="подтвердить квалификацию"
            @click="postCompaniesConfirmSendSmsAsync"
          />
        </div>
      </div>
    </div>
    <div v-if="errorMessage" class="row">
      <div class="col-md-12">
        <div class="potok-color-red">{{ errorMessage }}</div>
      </div>
    </div>
    <ModalFull
      v-if="isShowHelpMessage"
      :title="'зачем это нужно?'"
      @close="isShowHelpMessage = false"
    >
      <template #content>
        <div class="potok-text-body-1 potok-color-deepdive">
          В соответствии с Федеральным законом № 259-ФЗ физические лица, не
          являющиеся квалифицированными инвесторами, не могут * инвестировать
          более 600 000 рублей в год на всех инвестиционных платформах. Чтобы
          снять это ограничение —подтвердите квалификацию, выбрав подходящие
          условия, и загрузите документ
          <div
            style="
              margin-top: 20px;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              color: #818e99;
            "
          >
            * это ограничение не распространяется на стратегии Без риска,
            (Альфа) Консервативная, Базовая, Агрессивная
          </div>
        </div>
      </template>
    </ModalFull>
  </section>
</template>
<script setup>
import { ref, reactive, computed, nextTick } from 'vue';

import ModalFull from '@/components/_generic/modals/ModalFull.vue';
import QuestionPopover from '@/components/_generic/QuestionPopover.vue';
import QualificationModalSMSConfirm from '@/components/Qualification/QualificationModalSMSConfirm.vue';
import QualificationDocumentElement from '@/components/Qualification/QualificationDocumentElement.vue';

import { useStateStore } from '@/store/stateStore';
import server from '@/server';
import constants from '@/constants';
import imageConstants from '@/constants/imageConstants';
import { PotokButton } from 'potok-uikit';

const store = useStateStore();

const loading = ref(false);
const isShowModalTwoFactorAuth = ref(false);
const documents = ref([]);
const form = reactive({
  hasQiSecurities: false,
  hasQiExperience: false,
  hasQiSecuritiesDeals: false,
  hasQiProperty: false,
  hasQiEducation: false,
  hasQiBroker: false,
});

const isShowHelpMessage = ref(false);
const errorMessage = ref(false);
const isShowInputElement = ref(false);

const companyFl = computed(() => {
  return store.state.dictionaries.companies.find(
    (item) => item.typeId === constants.companyTypes.person,
  );
});

const isValidForm = () => {
  errorMessage.value = null;
  const {
    hasQiSecurities,
    hasQiExperience,
    hasQiSecuritiesDeals,
    hasQiProperty,
    hasQiEducation,
    hasQiBroker,
  } = form;
  if (
    !(
      hasQiSecurities ||
      hasQiExperience ||
      hasQiSecuritiesDeals ||
      hasQiProperty ||
      hasQiEducation ||
      hasQiBroker
    ) ||
    !documents.value.some((i) => i.file)
  ) {
    errorMessage.value = 'выберите условия и загрузите документ';
    return false;
  }
  if (!companyFl.value) {
    errorMessage.value = 'нет аккаунта физ. лица для квалификации';
    return false;
  }
  return true;
};

const postCompaniesConfirmSendSmsAsync = () => {
  if (!isValidForm()) {
    return;
  }

  const query = {
    id: companyFl.value.id,
  };
  loading.value = true;
  return server.postCompaniesQualificationUpdateSendSms
    .send(query)
    .pipe(onPostCompaniesConfirmEulaSendSmsAsyncSuccess.bind(this, query))
    .exec();
};
const onPostCompaniesConfirmEulaSendSmsAsyncSuccess = () => {
  loading.value = false;
  isShowModalTwoFactorAuth.value = true;
};

const addDocument = () => {
  isShowInputElement.value = false;
  errorMessage.value = null;
  nextTick(() => {
    isShowInputElement.value = true;
    nextTick(() => {
      document.getElementById('fileInput').click();
    });
  });
};
const addDocumentFile = (event) => {
  errorMessage.value = null;
  let selectedFile = event.target.files[0];
  if (!selectedFile) {
    return false;
  }
  if (selectedFile.size > 7340032) {
    errorMessage.value = 'файл не должен быть больше 7 Mb';
    return false;
  }
  documents.value.push({
    file: selectedFile,
  });
};

const removeDocument = (indexItem) => {
  documents.value.splice(documents.value.indexOf(indexItem), 1);
};

const onCloseModalTwoFactorAuth = () => {
  isShowModalTwoFactorAuth.value = false;
};
</script>
<style lang="scss" scoped>
@import '@/scss/typography.scss';

label {
  display: block;
}
.button-help {
  margin-left: auto;
  @media (max-width: $size_767) {
    margin-top: 16px;
    margin-left: initial;
  }
}

.content-wrapper {
  margin-top: 30px;
  @media (max-width: $size_767) {
    margin-top: 10px;
  }
}
.qualification_wrapper {
  padding-top: 30px;
  text-transform: none;
  @media (max-width: $size_991) {
    padding-top: 20px;
  }
}

.qualification-files {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
}

.qualification-files-add {
  display: flex;
  align-items: center;

  .qualification-files-add-text {
    margin-right: 10px;
    @media (max-width: $size_991) {
      width: initial;
    }
  }
}
.qualification-checkboxes {
  background: #ffffff;
  border-radius: 14px;
  min-height: 150px;
  margin-bottom: 30px;
  cursor: pointer;
  @media (max-width: $size_767) {
    min-height: 116px;
    margin-bottom: 15px;
  }

  .checkbox-row > label {
    padding: 20px;
    cursor: pointer;
    @media (max-width: $size_767) {
      padding: 15px;
    }
    .label-items {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    .label-text {
      cursor: pointer;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $deep_dive;
      @media (max-width: $size_767) {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  .checkbox-hidden {
    &:checked + label {
      .check {
        background-image: url('@img/icons/qualification/check-recatangle.svg');
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        background-size: cover;
        border-color: initial;
      }
    }
  }
  .checkbox-row {
    .check {
      width: 20px;
      height: 20px;
      border-radius: 4px;
      background-color: #f2f4f8;
      border: 0;
      &:hover {
        background-image: url('@img/icons/qualification/check-recatangle-hover.svg');
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        background-size: cover;
        border-color: initial;
      }
    }
  }
}

.row-left {
  display: flex;
  justify-content: left;
}
.row-center {
  display: flex;
  justify-content: center;
}

.qualification-title {
  @media (max-width: $size_991) {
    @include potok-text-h2;
  }
}
.qualification-subtitle {
  @media (max-width: $size_991) {
    @include potok-text-body-2;
  }
}

.qualification-checkboxes {
  .checkbox-row {
    margin-bottom: 18px;
    label .label-items {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
    }
    .check {
      margin-right: 0px;
    }
    .qualification {
      display: inline-block;
      width: 24px;
      height: 24px;
    }
    .qualification-teacher {
      background: url('@img/icons/qualification/qualification-teacher.svg')
        no-repeat;
      background-size: cover;
    }
    .qualification-status-up {
      background: url('@img/icons/qualification/qualification-status-up.svg')
        no-repeat;
      background-size: cover;
    }
    .qualification-clock {
      background: url('@img/icons/qualification/qualification-clock.svg')
        no-repeat;
      background-size: cover;
    }
    .qualification-lock {
      background: url('@img/icons/qualification/qualification-lock.svg')
        no-repeat;
      background-size: cover;
    }
    .qualification-shopping-cart {
      background: url('@img/icons/qualification/qualification-shopping-cart.svg')
        no-repeat;
      background-size: cover;
    }
    .qualification-broker {
      background: url('@img/icons/qualification/qualification-broker.svg')
        no-repeat;
      background-size: cover;
    }
  }
}

.label-text-wrapper {
  text-align: left;
  color: #313132;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
}
.img-inputer {
  .img-inputer__placeholder {
    top: 49%;
  }
}
.qualification-files-wrapper {
  @media (max-width: $size_991) {
    margin-top: 5px;
  }
}
</style>
