import { isWithinInterval, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const formatDateInTimeZone = (date, timezone = 'Europe/Moscow', format = 'yyyy-MM-dd HH:mm:ss'  ) => {
  return formatInTimeZone(date, timezone, format)
}

export const isAvailableBetweenDates = (date, start, end  ) => {
  return isWithinInterval(
    date,
    { start: parseISO(start), end: parseISO(end) }
  )
}