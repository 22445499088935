export default {
  getFeatures: {
    url: '/api/features',
    method: 'GET',
  },
  getFeaturesBuybacks: {
    url: '/api/features/investor-buybacks',
    method: 'GET',
  },
  getFeaturesRemainingBalance: {
    url: '/api/features/remaining-balance-interest-promo',
    method: 'GET',
  },
  getFeaturesStatusBonus: {
    url: `/api/features/status-bonus`,
    method: 'GET',
  },
  getFeaturesEnhancedDiversification: {
    url: '/api/features/enhanced-diversification',
    method: 'GET',
  },
  getInvestingPotokLoansBanner: {
    url: '/api/features/investing-potok-loans-banner',
    method: 'GET',
  },
  getExclusivePotokLoansBanner: {
    url: '/api/features/exclusive-potok-loans-offer',
    method: 'GET',
  },
};
