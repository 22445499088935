<template>
  <div class="assets-returns">
    <div class="assets-returns-head mb-5">
      <h2 class="potok-text-body-1-bold my-0">инвестиции по поколениям</h2>

      <Switcher
        v-model="selectedPeriod"
        class="assets-returns-switcher"
        :items="periods"
        small="true"
        @update:model-value="updatePeriod"
      />
    </div>

    <AnalyticsLoadingContainer
      :loading="props.loading"
      loading-text="пожалуйста, подождите, загружаем данные"
      help-mobile-title="инвестиции по поколениям"
    >
      <div style="width: 100%; height: 240px">
        <Bar
          v-if="isShowGraph && isLabels"
          :data="dataChart"
          :options="options"
          :width="'100%'"
          :height="'240px'"
          :plugins="[chartAreaBorder]"
        />
      </div>

      <template #popper>
        <div class="popup help-dropdown-text potok-text-body-2">
          <div class="mb-5">
            <div v-html="props.texts.hint.fullDescription"></div>
          </div>
        </div>
      </template>
    </AnalyticsLoadingContainer>
  </div>
</template>

<script setup>
import { computed, nextTick, ref, watch } from 'vue';
import { Bar } from 'vue-chartjs';
import { Chart, registerables } from 'chart.js';
import { format, parse } from 'date-fns';
import Switcher from '@/components/_generic/cselect/Switcher.vue';

import externalTooltipHandler from '@/utils/chartjs/getOrCreateTooltip';
import chartAreaBorder from '@/utils/chartjs/chartAreaBorder';
import AnalyticsLoadingContainer from './AnalyticsLoadingContainer.vue';

Chart.register(...registerables);

const emit = defineEmits(['updatePeriod']);
const props = defineProps({
  loading: Boolean,
  data: {
    type: Object,
    default: () => null,
  },
  texts: {
    type: Object,
    default: () => {},
  },
});

const periods = ref([
  {
    title: 'год',
    value: 'month',
  },
  {
    title: 'все',
    value: 'quarter',
  },
]);
const selectedPeriod = ref('month');

const dataChart = ref({
  labels: [],
  datasets: [
    {
      label: 'выплачивается',
      data: [],
      borderColor: 'rgba(23, 59, 87, 1)',
      backgroundColor: 'rgba(23, 59, 87, 1)',
      borderRadius: 4,
      order: 1,
      minBarLength: 1,
    },
    {
      label: 'реструктаризация',
      data: [],
      borderColor: 'rgba(255, 122, 47, 1)',
      backgroundColor: 'rgba(255, 122, 47, 1)',
      borderRadius: 4,
      order: 2,
      minBarLength: 1,
    },
    {
      label: 'в просрочке',
      data: [],
      borderColor: 'rgba(255, 193, 30, 1)',
      backgroundColor: 'rgba(255, 193, 30, 1)',
      borderRadius: 4,
      order: 3,
      minBarLength: 1,
    },
    {
      label: 'в дефолте',
      data: [],
      borderColor: 'rgba(238, 61, 72, 1)',
      backgroundColor: 'rgba(238, 61, 72, 1)',
      borderRadius: 4,
      order: 4,
      minBarLength: 1,
    },
    {
      label: 'выплачено',
      data: [],
      borderColor: 'rgba(220, 224, 227, 1)',
      backgroundColor: 'rgba(220, 224, 227, 1)',
      borderRadius: 4,
      order: 5,
      minBarLength: 1,
    },
    {
      label: 'продано',
      data: [],
      borderColor: 'rgba(4, 204, 217, 1)',
      backgroundColor: 'rgba(4, 204, 217, 1)',
      borderRadius: 4,
      order: 6,
      minBarLength: 1,
    },
    {
      label: 'выкупленные дефолты',
      data: [],
      borderColor: '#0382ce',
      backgroundColor: '#0382ce',
      borderRadius: 4,
      order: 7,
      minBarLength: 1,
    },
  ],
});

const options = ref({
  metaConfig: {
    tooltipFooter: true,
    tooltipFooterText: 'итого',
    tooltipHeaderFormat: 'month',
    defaultLabels: null,
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      align: 'start',
      labels: {
        boxWidth: 10,
        boxHeight: 10,
        usePointStyle: true,
        pointStyle: 'circle',
        color: 'rgba(129, 142, 153, 1)',
        font: {
          size: 14,
        },
      },
    },
    tooltip: {
      enabled: false,
      position: 'nearest',
      external: externalTooltipHandler,
      padding: 0,
    },
    chartAreaBorder: {
      borderColor: 'rgba(232, 236, 240, 1)',
      borderWidth: 1,
    },
  },
  elements: {
    point: {
      pointStyle: 'circle',
      radius: 0,
      borderWidth: 2,
    },
    line: {
      borderWidth: 2,
    },
  },
  scales: {
    y: {
      stacked: true,
      beginAtZero: true,
      grid: {
        display: true,
        drawTicks: false,
        color: 'rgba(232, 236, 240, 1)',
        width: 1,
      },
      border: {
        color: 'rgba(243, 245, 247, 1)',
        width: 1,
      },
      ticks: {
        display: false,
      },
    },
    x: {
      stacked: true,
      grid: {
        display: true,
        drawTicks: false,
        color: 'rgba(243, 245, 247, 1)',
        tickBorderDash: [5, 3],
        width: 1,
      },
      border: {
        dash: [5, 3],
        color: 'rgba(243, 245, 247, 1)',
        width: 1,
      },
      ticks: {
        padding: 8,
        maxRotation: 0,
        minRotation: 0,
        color: 'rgba(129, 142, 153, 0.8)',
        font: {
          size: 8,
          family: "'SuisseIntl'",
          weight: '400',
        },
      },
    },
  },
});

const isShowGraph = ref(false);

const items = computed(() => {
  if (!props.data) {
    return [];
  }
  const keys = Object.keys(props.data);
  const values = Object.values(props.data);

  const result = keys.map((el, i) => {
    return {
      label: keys[i],
      ...values[i],
    };
  });

  const filteredResult = result.filter((el) => {
    let sum = 0;

    for (const item of Object.values(el)) {
      if (typeof item === 'number') {
        sum += item;
      }
    }

    return sum >= 1;
  });

  return filteredResult;
});

const labels = computed(() => {
  return items.value.map((el) => {
    if (selectedPeriod.value === 'month') {
      return format(parse(el.label, 'dd.MM.yyyy', new Date()), 'MM.yy');
    }

    return format(parse(el.label, 'dd.MM.yyyy', new Date()), 'qКyy');
  });
});

const isLabels = computed(() => {
  return dataChart.value.labels.length;
});

const updatePeriod = () => {
  emit('updatePeriod', selectedPeriod.value);
};

watch(items, () => {
  isShowGraph.value = false;
  const newDataFunded = [];
  const newDataRestructured = [];
  const newDataOverdue = [];
  const newDataDefaulted = [];
  const newDataRefunded = [];
  const newDataSoldPortfolio = [];
  const newSoldRiskOff = [];

  items.value.forEach((el) => {
    newDataFunded.push(el.funded);
    newDataRestructured.push(el.restructured);
    newDataOverdue.push(el.overdue);
    newDataDefaulted.push(el.defaulted);
    newDataRefunded.push(el.refunded);
    newDataSoldPortfolio.push(el.soldPortfolio);
    newSoldRiskOff.push(el.soldRiskOff);
  });

  dataChart.value.datasets[0].data = newDataFunded;
  dataChart.value.datasets[0].label = props.texts?.keys?.funded?.name;

  dataChart.value.datasets[1].data = newDataRestructured;
  dataChart.value.datasets[1].label = props.texts?.keys?.restructured?.name;

  dataChart.value.datasets[2].data = newDataOverdue;
  dataChart.value.datasets[2].label = props.texts?.keys?.overdue?.name;

  dataChart.value.datasets[3].data = newDataDefaulted;
  dataChart.value.datasets[3].label = props.texts?.keys?.defaulted?.name;

  dataChart.value.datasets[4].data = newDataRefunded;
  dataChart.value.datasets[4].label = props.texts?.keys?.refunded?.name;

  dataChart.value.datasets[5].data = newDataSoldPortfolio;
  dataChart.value.datasets[5].label = props.texts?.keys?.soldPortfolio?.name;

  dataChart.value.datasets[6].data = newSoldRiskOff;
  dataChart.value.datasets[6].label = props.texts?.keys?.soldRiskOff?.name;

  dataChart.value.labels = labels.value;

  options.value.metaConfig.tooltipHeaderFormat = selectedPeriod.value;
  options.value.metaConfig.defaultLabels = items.value.map((el) => el.label);
  // Если отображаем дни, то скрываем лейблы на оси x
  options.value.scales.x.grid.display = selectedPeriod.value !== 'day';
  options.value.scales.x.ticks.display = selectedPeriod.value !== 'day';

  nextTick(() => {
    isShowGraph.value = true;
  });
});
</script>

<style scoped lang="scss">
.assets-returns-head {
  display: flex;
  justify-content: space-between;

  @media (max-width: $size_991) {
    flex-direction: column;
  }
}
.assets-returns-switcher {
  @media (max-width: $size_991) {
    margin-top: 16px;
  }
}
.help-dropdown-text {
  max-width: 560px;
  color: $deep_dive;
}
</style>
