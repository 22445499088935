<template>
  <div>
    <button class="button_filters" type="button" @click="onClickOpenFilters">
      <i class="icon_filters"></i> фильтры
    </button>

    <ModalFull v-if="isShowModalFilters" :title="'фильтры'" @close="close">
      <template #content>
        <div class="row">
          <div class="col-md-12" style="margin-top: 16px">
            <range-datepicker-years
              :init-date-since="dateSince"
              :init-date-to="dateTo"
              @change="selectDateRange"
            />
          </div>
          <div class="col-md-12" style="margin-top: 16px">
            <CSelect
              v-model="type"
              :items="projectMessageTypes"
              :disabled="loadingProjectMessageTypes"
              @update:model-value="changeType"
            ></CSelect>
          </div>
          <div class="col-md-12" style="margin-top: 16px">
            <CSelect
              v-model="investmentType"
              :disabled="disabledInvestmentTypeByTransactionType"
              placeholder="Тип инвестиции"
              :items="investmentTypes"
              @update:model-value="changeInvestmentType"
            >
            </CSelect>
          </div>
        </div>
      </template>
      <template #actions>
        <div class="actions_wrapper">
          <button
            :disabled="props.loading"
            type="button"
            class="button analytics-history-mobile-accept button-primary-outline"
            style="margin-right: 10px; width: 139px"
            @click="onClickApplyFilters"
          >
            применить
          </button>
          <button
            :disabled="props.loading"
            type="button"
            class="button button-grey-outline"
            style="width: 139px"
            @click="onClickResetFilters"
          >
            сбросить
          </button>
        </div>
      </template>
    </ModalFull>
  </div>
</template>
<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import server from '@/server/index';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';

import { CSelect } from 'potok-uikit';
import RangeDatepickerYears from '@/components/_generic/rangedatapicker/RangeDatepickerYears.vue';
import { investmentTypes } from '@/constants/Analytics/filterConstants';

const props = defineProps({
  dateSince: Object,
  dateTo: Object,
  type: String,
  investmentType: String,
  loading: Boolean,
});
const emit = defineEmits([
  'getAccountStatement',
  'changeDateRange',
  'changeType',
  'changeInvestmentType',
  'resetFilters',
]);

const projectMessageTypes = ref([]);
const isShowModalFilters = ref(false);
const loadingProjectMessageTypes = ref(true);

const dateSince = ref(props.dateSince);
const dateTo = ref(props.dateTo);
const type = ref(props.type);
const investmentType = ref(props.investmentType);

const disabledInvestmentTypeByTransactionType = computed(() => {
  return ['outcome', 'income', 'deposit'].indexOf(props.type) !== -1;
});

onMounted(() => {
  getOperationTypes();
});

const onClickOpenFilters = () => {
  isShowModalFilters.value = true;
};
const close = () => {
  isShowModalFilters.value = false;
};

const onClickApplyFilters = () => {
  isShowModalFilters.value = false;
  emit('getAccountStatement');
};
const onClickResetFilters = () => {
  emit('resetFilters');
  isShowModalFilters.value = false;
};

const selectDateRange = (dateRange) => {
  emit('changeDateRange', {
    dateSince: dateRange.dateSince,
    dateTo: dateRange.dateTo,
  });
};
const changeType = () => {
  emit('changeType', type.value);
};
const changeInvestmentType = () => {
  emit('changeInvestmentType', investmentType.value);
};
const initData = () => {
  dateSince.value = props.dateSince;
  dateTo.value = props.dateTo;
  type.value = props.type;
  investmentType.value = props.investmentType;
};

const getOperationTypes = () => {
  return server.getCompaniesAccountStatementOperationTypes
    .send()
    .pipe(onGetOperationTypesSuccess.bind(this), () => {
      loadingProjectMessageTypes.value = false;
    })
    .exec();
};

const onGetOperationTypesSuccess = ({ data }) => {
  projectMessageTypes.value = data;
  loadingProjectMessageTypes.value = false;
};

watch(isShowModalFilters, () => {
  if (isShowModalFilters.value) {
    initData();
  }
});
</script>
<style lang="scss" scoped>
.button_filters {
  background: #e5e9ee;
  border-radius: 8px;
  border: 0;
  height: 44px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.actions_wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.analytics-history-mobile-accept {
  height: 44px;
}
</style>
