<template>
  <section class="investor page">
    <template v-if="store.state.loaders.isUserLoading">
      <Loader />
    </template>
    <template v-if="!store.state.loaders.isUserLoading">
      <!-- банеры/модалки -->
      <BannerNewUser v-if="isNeedOnboarding" />
      <!-- <DashboardModalConfirmRegistration
        v-if="isShowConfirmMessage && store.isEnableQualifiaction && !store.isAlfaCustomer"
        @close="isShowConfirmMessage = false" /> -->
      <dashboard-exclusive-loans-banner
        v-if="isMobile && isInvestingExclusiveLoansBanner"
        class="mb-7"
      />
      <DashboardModalBecomePartner
        v-if="isShowBecomePartner"
        @close="isShowBecomePartner = false"
      />
      <RejectedPassport
        v-if="isShowRejectedPassportModal"
        @close="isShowRejectedPassportModal = false"
      />

      <!-- сообщения -->
      <notification-message />
      <NeedJoinEula v-if="needJoinEula" />

      <!-- онбординг -->
      <DashboardOnboard
        v-if="isNeedOnboarding"
        @show-confirm-message="isShowConfirmMessage = true"
      />

      <!-- блок после онбординга -->
      <template v-if="!isNeedOnboarding">
        <dashboard-bonus-banner
          v-if="isNotAlfaCustomer && isNotFirstIncome"
          class="dashboard-bonus-banner-container"
        />
        <!-- блок именем/названием, статусом, менюшкой -->
        <dashboard-title
          :is-show-menu-from-tour="isShowTitleMenuFromTour"
          @invoke-show-become-parnter="isShowBecomePartner = true"
        />

        <dashboard-exclusive-loans-banner
          v-if="!isMobile && isInvestingExclusiveLoansBanner"
          class="mt-7"
        />

        <dashboard-title-welcome-tour v-if="isMobile" is-mobile />
        <!-- блок с информацией о том что юзеру нужно загрузить паспорт или что то подобное -->
        <user-message
          @open-rejected-passport="isShowRejectedPassportModal = true"
        />
        <!-- основные 4 блока для юзера на дашборде -->
        <dashboard-balances />
        <!-- таблица по инвестициям юзера -->
        <dashboard-investments />
      </template>
      <!-- небольшая сводка актуальной инфы по займам -->
      <dashboard-pulse />
      <!-- график со статистикой потока за последние года -->
      <dashboard-statistic />
    </template>
  </section>
</template>
<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useStateStore } from '@/store/stateStore';

import constants from '@/constants';
import { Loader } from 'potok-uikit';
import NotificationMessage from '@/components/NotificationMessage/NotificationMessage.vue';
import NeedJoinEula from '@/components/_generic/messages/NeedJoinEula.vue';
import UserMessage from '@/components/_generic/messages/UserMessage.vue';
import DashboardBonusBanner from '@/components/Dashboard/modals/DashboardBonusBanner.vue';

import tracker from '@/tracker';

import DashboardTitle from '@/components/Dashboard/DashboardTitle.vue';
import DashboardBalances from '@/components/Dashboard/DashboardBalances.vue';
import DashboardInvestments from '@/components/Dashboard/DashboardInvestments.vue';
import DashboardPulse from '@/components/Dashboard/DashboardPulse.vue';
import DashboardStatistic from '@/components/Dashboard/DashboardStatistic.vue';
import BannerNewUser from '@/components/_generic/banners/BannerNewUser.vue';
import DashboardModalBecomePartner from '@/components/Dashboard/modals/DashboardModalBecomePartner.vue';
import DashboardOnboard from '@/pages/Dashboard/DashboardOnboard.vue';
import RejectedPassport from '@/components/_modals/RejectedPassport.vue';
import DashboardTitleWelcomeTour from '@/components/Dashboard/DashboardTitle/DashboardTitleWelcomeTour.vue';
import DashboardExclusiveLoansBanner from '@/components/Dashboard/modals/DashboardExclusiveLoansBanner';
import detectDevice from '@/composables/detectDeviceComposable';
import server from '@/server';

const store = useStateStore();
const { isMobile, isTablet } = detectDevice();

// Экземпляр тура
let tg;

const tourSteps = [
  {
    content: 'сейчас мы проверяем ваш паспорт',
    title: 'подтверждение<br>паспорта',
    target: '[data-tg-id="1"]',
    order: 1,
    afterEnter: () => {
      if (tg.activeStep === 0 && isTablet.value) {
        tg.setOptions({
          dialogPlacement: undefined,
          targetPadding: 30,
        });
        setTimeout(() => {
          tg.updatePositions();
        }, 200);
      }
    },
  },
  {
    content:
      'выберите одну из стратегий автоинвестирования, чтобы деньги начали работать без дополнительных действий',
    title: 'выберите стратегию',
    target: '[data-tg-id="2"]',
    order: 2,
    afterEnter: () => {
      if (tg.activeStep === 1 && isTablet.value) {
        tg.setOptions({
          dialogPlacement: 'bottom',
        });
        setTimeout(() => {
          tg.updatePositions();
        }, 200);
      }
    },
  },
  {
    content:
      'пополните счёт или дождитесь пока поступят деньги, если вы отправили их ранее',
    title: 'пополните счёт',
    target: '[data-tg-id="3"]',
    order: 3,
    afterEnter: () => {
      if (tg.activeStep === 2 && isTablet.value) {
        tg.setOptions({
          dialogPlacement: 'bottom',
        });
        setTimeout(() => {
          tg.updatePositions();
        }, 200);
      }
    },
  },
  {
    content:
      'в списке новых займов вы можете самостоятельно выбрать займ для инвестирования',
    title: 'инвестируйте вручную',
    target: '[data-tg-id="4"]',
    order: 4,
    afterEnter: () => {
      if (tg.activeStep === 3 && isTablet.value) {
        tg.setOptions({
          dialogPlacement: 'bottom',
          targetPadding: 20,
        });
        tg.updatePositions();
      }
    },
  },
  {
    content:
      'Не хотите ждать выплат от заемщиков? Продайте портфель Потоку в несколько кликов',
    title: 'легкий выход из инвестирования',
    target: '[data-tg-id="5"]',
    order: 5,
  },
  {
    content: 'выводите свободные деньги на счет: отправим в любой банк',
    title: 'вывод денег',
    target: '[data-tg-id="6"]',
    order: 6,
  },
];

const isShowConfirmMessage = ref(false);
const isShowBecomePartner = ref(false);
const isShowRejectedPassportModal = ref(false);
const isShowTitleMenuFromTour = ref(false);

const isNotAlfaCustomer = computed(() => {
  return !store.isAlfaCustomer;
});
const isNotFirstIncome = computed(() => {
  return !store.isFirstIncomeAt;
});
const isAllTourLoadingsDone = computed(() => {
  return (
    !store.state.loaders.isPulseProjectStatsLoading &&
    !store.state.loaders.isUserLoading &&
    !store.state.loaders.isPlatformStatFutureLoading
  );
});
const isTourCompleted = computed(() => {
  return store.state.user.isTourCompleted;
});

const selectedCompany = computed(() => {
  return store.selectedCompany;
});
const user = computed(() => {
  return store.state.user;
});

const isTourReady = computed(() => {
  return isOnboardingCompleted.value && isAllTourLoadingsDone.value;
});
const isOnboardingCompleted = computed(() => {
  return user.value.isOnboardingComplete === true;
});

const isBonusFromBalanceActive = computed(() => {
  return store.state.features.general.remainingBalanceInterestPromo.enabled;
});

const isInvestingExclusiveLoansBanner = computed(() => {
  return store.state.features.general.exclusivePotokLoansOffer?.enabled;
});

const needJoinEula = computed(() => {
  if (
    selectedCompany.value &&
    selectedCompany.value.typeId !== constants.companyTypes.person
  ) {
    return (
      selectedCompany.value.isEulaApproved &&
      !selectedCompany.value.isAgreeRules
    );
  }
  return false;
});

const isNeedOnboarding = computed(() => {
  if (
    !store.state.loaders.isUserLoading &&
    user.value.isOnboardingComplete != undefined &&
    user.value.isOnboardingComplete === false
  ) {
    return true;
  }
  return false;
});

const postTourComplete = () => {
  return server.postUserOnboardTourComplete
    .send()
    .pipe(() => {
      store.setTourCompleted(true);
    })
    .exec();
};

const initialTour = () => {
  tg = new tourguide.TourGuideClient({
    targetPadding: 30,
    nextLabel: 'далее',
    prevLabel: 'назад',
    finishLabel: 'ок',
    steps: tourSteps,
  });
  tg.onAfterExit(() => {
    isShowTitleMenuFromTour.value = false;
    postTourComplete();
  });
  tg.start();
};

watch(
  isTourReady,
  () => {
    if (isTourReady.value && !isTourCompleted.value) {
      isShowTitleMenuFromTour.value = true;
      setTimeout(() => {
        const el = document.querySelector('.tg-dialog');
        if (!el || el?.style.display !== 'block') {
          initialTour();
        }
      }, 1500);
    }
  },
  { immediate: true },
);

const getBonusFromBalance = () => {
  const query = {
    companyId: store.selectedCompanyId,
  };
  return server.getFeaturesRemainingBalance
    .send(query)
    .pipe(onGetBonusFromBalanceSuccess, () => {})
    .exec();
};

const onGetBonusFromBalanceSuccess = ({ data }) => {
  store.state.features.remainingBalanceInterestPromo = data;
};

watch(
  () => {
    return store.selectedCompanyId;
  },
  (value) => {
    if (value && isBonusFromBalanceActive.value) {
      getBonusFromBalance();
    }
  },
);

watch(
  () => {
    return store.state.loaders.isFeaturesLoading;
  },
  (value) => {
    if (!value && store.selectedCompanyId && isBonusFromBalanceActive.value) {
      getBonusFromBalance();
    }
  },
);

onMounted(() => {
  tracker.queue(tracker.commands.SEND, 'Investor_LK_My_Dashboard');
});
</script>
<style lang="scss" scoped>
.investor {
  margin-bottom: 100px;
}

.dashboard-bonus-banner-container {
  margin-bottom: 30px;

  @media (max-width: $size_767) {
    margin-bottom: 20px;
  }
}

:deep(.content_title) {
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 9px;

  color: $grey;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: $size_767) {
    font-size: 10px;
    line-height: 12px;
  }

  .content_title_text {
    letter-spacing: 0.1em;
    text-transform: uppercase;

    @media (max-width: $size_767) {
      font-size: 8px;
      line-height: 20px;
    }
  }
}

:deep(.block_title) {
  @include potok-text-h1;
  color: $deep_dive;

  @media (max-width: $size_767) {
    @include potok-text-h2;
  }
}

:deep(.dashboard_block) {
  @media (max-width: $size_1200) {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: initial;
    }
  }

  .dashboard_block_content {
    background: #ffffff;
    border-radius: 14px;
    padding: 20px 20px 20px 20px;

    @media (max-width: $size_767) {
      padding: 12px 20px;
    }

    .content_main_value {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: -0.01em;
      color: $deep_dive;

      @media (max-width: $size_767) {
        font-size: 20px !important;
        line-height: 18px !important;
      }
    }

    .item_row {
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      flex-direction: column;
      flex-wrap: wrap;

      @media (max-width: $size_767) {
        align-items: baseline;
      }
    }

    .item_title {
      color: $grey;
      max-width: 180px;
    }

    .item_value {
      color: $deep_dive;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        margin-right: 5px;
        cursor: pointer;
      }
    }

    .item_row_vertical {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      margin: 15px 0;

      .item_title {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #999999;
        max-width: 200px;
        font-size: 12px;
        line-height: 14px;
        padding: 6px 0;

        @media (max-width: $size_767) {
          font-size: 14px;
          line-height: 20px;
        }
      }

      .item_value {
        font-size: 14px;
        line-height: 16px;

        @media (max-width: $size_767) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    .autoinvest_switcher {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: right;
      letter-spacing: -0.02em;
      color: #04ccd9;
      margin: 0 6px;
      cursor: pointer;
    }
  }
}

:deep(.button-balances) {
  width: 100%;

  @media (max-width: $size_767) {
    margin: 0 auto;
  }
}
</style>
