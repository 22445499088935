<template>
  <div class="holding-banner">
    <div class="holding-banner-title potok-text-h2">
      доходность <span class="potok-color-tiffany">от 25%</span><br>
      на любые вложения
    </div>
    <div class="holding-banner-description mt-4">
      займы Поток.Холдинга - инвестируйте деньги на короткие сроки и получайте гарантированный высокий доход. Ставка фиксирована.
    </div>
    <div class="potok-banner-need-do potok-text-uppercase">
      что нужно сделать:
    </div>
    <div
      v-for="item in toDoItems"
      class="potok-banner-item-to-do mt-3"
    >
      <PotokImg
        :src="ImageConstants.loans.bannerPotokHolding.checkMarkInCircleTiffany"
        height="12"
        width="12"
        class="mr-2"
      />
      {{ item }}
    </div>

    <div class="potok-banner-divider potok-divider mt-5 mb-5" />

    <div class="potok-banner-bottom">
      <PotokImg
        :src="ImageConstants.loans.bannerPotokHolding.flashTiffany"
        height="24"
        width="24"
        class="potok-banner-flash mr-2"
      />
      торопитесь, сбор для данных заемщиков закрывается очень быстро!
    </div>
  </div>
</template>

<script setup>
import ImageConstants from "@/constants/imageConstants";
import PotokImg from '@/components/_generic/PotokImg.vue';

const toDoItems = [
  'выберите карточку заемщика',
  'нажмите кнопку инвестировать',
  'напишите сумму и подпишите СМС',
  'получайте доход, следите в ЛК',
]
</script>

<style lang="scss" scoped>
.holding-banner {
  background: $breakwater;
  text-transform: initial;
  border-radius: 14px;
  color: $white;
  padding: 30px 20px;
}
.potok-banner-need-do {
  margin-top: 80px;
}
.potok-banner-divider {
  border-top: 1px solid rgba(232, 236, 240, 0.1);
}
.potok-banner-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
}
.potok-banner-flash {
  -webkit-animation: flash 2s infinite;
}

@keyframes flash {
  0% { filter: brightness(0) saturate(100%) invert(70%) sepia(26%) saturate(7219%) hue-rotate(140deg) brightness(100%) contrast(97%); }
  33% { filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(289deg) brightness(101%) contrast(101%); }
  66% { filter: brightness(0) saturate(100%) invert(75%) sepia(93%) saturate(647%) hue-rotate(339deg) brightness(101%) contrast(101%); }
  100% { filter: brightness(0) saturate(100%) invert(70%) sepia(26%) saturate(7219%) hue-rotate(140deg) brightness(100%) contrast(97%); }
}
</style>