<template>
  <div class="block-menu-mobile">
    <div
      class="block-menu-mobile-item"
      style="margin-bottom: 24px; text-transform: none"
    >
      <CSelect v-model="localSelectedMenu" :items="items" style="margin-top: 0">
      </CSelect>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStateStore } from '@/store/stateStore';
import { CSelect } from 'potok-uikit';

const localSelectedMenu = ref();
const route = useRoute();
const router = useRouter();
const store = useStateStore();

const isAdmin = computed(() => store.isAdmin);

const items = computed(() => {
  const tabs = [
    {
      value: 'analyticsMyPortfolio',
      title: 'мой портфель',
    },
    {
      value: 'analyticsHistoryOperation',
      title: 'история операций',
    },
    {
      value: 'analyticsHistoryEvent',
      title: 'история событий',
    },
  ];

  if (isAdmin.value || store.isNewAnalyticsEnabled) {
    tabs.push({
      value: 'analyticsAssets',
      title: 'мои активы',
    });
  }

  return tabs;
});

watch(localSelectedMenu, () => {
  onChangeSelectMenu();
});

onMounted(() => {
  localSelectedMenu.value = route.name;
});

const onChangeSelectMenu = () => {
  router.push({
    name: localSelectedMenu.value,
  });
};
</script>
<style lang="scss" scoped>
.block-menu-mobile {
  width: 100%;
  .block-menu-select {
    @media (max-width: $size_767) {
      width: 100%;
    }
  }
}
</style>
