<template>
  <div class="block_content">
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-3 filter-item">
        <range-datepicker-years
          :init-date-since="dateSince"
          :init-date-to="dateTo"
          @change="selectDateRange"
        />
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 filter-item">
        <CSelect
          v-model="type"
          :items="projectMessageTypes"
          :disabled="loadingProjectMessageTypes"
          @update:model-value="changeType"
        ></CSelect>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 filter-item">
        <CSelect
          v-model="investmentType"
          :disabled="disabledInvestmentTypeByTransactionType"
          placeholder="Тип инвестиции"
          :items="investmentTypes"
          @update:model-value="changeInvestmentType"
        >
        </CSelect>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3">
        <button
          :disabled="props.loading || v$.$invalid"
          type="button"
          class="button button-secondary-outline"
          @click="onClickGetAccountStatement()"
        >
          Показать
        </button>
      </div>
    </div>
    <div v-if="showAlertByOldDate" class="row" style="margin-top: 20px">
      <div class="col-md-12">
        <div class="history-alert-oldDate">
          <span
            style="color: hsl(0, 72%, 10%); line-height: 20px; font-weight: 500"
          >
            Уважаемый пользователь, данные по займам, закрытым до 31.12.2019,
            доступны только в скачиваемой версии отчета.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import server from '@/server/index';
import { isBefore } from 'date-fns';

import useVuelidate from '@vuelidate/core';
import validators from '@/validators';
import { CSelect } from 'potok-uikit';
import RangeDatepickerYears from '@/components/_generic/rangedatapicker/RangeDatepickerYears.vue';
import { investmentTypes } from '@/constants/Analytics/filterConstants';

const props = defineProps({
  dateSince: Object,
  dateTo: Object,
  type: String,
  investmentType: String,
  loading: Boolean,
});
const emit = defineEmits([
  'getAccountStatement',
  'changeDateRange',
  'changeType',
  'changeInvestmentType',
]);

const projectMessageTypes = ref([]);
const dateSince = ref(props.dateSince);
const dateTo = ref(props.dateTo);
const type = ref(props.type);
const investmentType = ref(props.investmentType);
const loadingProjectMessageTypes = ref(true);

const rulesValidateion = {
  dateSince: {
    required: validators.required,
  },
  dateTo: {
    required: validators.required,
  },
};
const v$ = useVuelidate(rulesValidateion, { dateSince, dateTo });

const disabledInvestmentTypeByTransactionType = computed(() => {
  return ['outcome', 'income', 'deposit'].indexOf(props.type) !== -1;
});

const showAlertByOldDate = computed(() => {
  return isBefore(dateSince.value, new Date(2020, 2, 27));
});

onMounted(() => {
  getOperationTypes();
});

const onClickGetAccountStatement = () => {
  emit('getAccountStatement');
};

const selectDateRange = (dateRange) => {
  emit('changeDateRange', {
    dateSince: dateRange.dateSince,
    dateTo: dateRange.dateTo,
  });
};
const changeType = () => {
  emit('changeType', type.value);
};
const changeInvestmentType = () => {
  emit('changeInvestmentType', investmentType.value);
};
const getOperationTypes = () => {
  return server.getCompaniesAccountStatementOperationTypes
    .send()
    .pipe(onGetOperationTypesSuccess.bind(this), () => {
      loadingProjectMessageTypes.value = false;
    })
    .exec();
};

const onGetOperationTypesSuccess = ({ data }) => {
  projectMessageTypes.value = data;
  loadingProjectMessageTypes.value = false;
};

</script>
<style lang="scss" scoped>
.history-alert-oldDate {
  display: inline-block;
  padding: 8px 0px;
  margin-bottom: 8px;
}
</style>
