<template>
  <section class="settings-security">
    <div class="security-auth content-block">
      <div class="security-auth-top">
        <span class="potok-text-h2-light">Двухфакторная авторизация</span>
        <SwitcherSlider
          v-if="user.email"
          v-model="isOtpEnabled"
          :disabled="loading"
          @update:model-value="putUsersMeProfileAsync"
        />
      </div>
      <div class="potok-color-grey security-text potok-text-body-2 mt-2">
        Для подключения 2-х факторной авторизации укажите email в разделе
        <router-link
          :to="{
            name: 'SettingsPerson',
          }"
          class="security-text-link potok-color-deepdive"
        >
          личные данные
        </router-link>
      </div>
    </div>

    <div class="security-password content-block mt-7">
      <div class="security-password-wrapper">
        <div class="potok-text-h2-light">
          изменить пароль
        </div>
        <FormFieldPasspord
          id="password"
          v-model="localRawPassword"
          placeholder="новый пароль"
          class="mt-2"
          @update:model-value="pressKeyPassword"
          @keyup.enter="changePassword"
        />
        <div
          v-if="errorMessagePassword"
          class="potok-color-red mt-2"
        >
          {{ errorMessagePassword }}
        </div>
        <div
          v-if="!errorMessagePassword"
          class="potok-color-grey mt-2"
        >
          минимум 6 символов
        </div>
        <PotokButton
          v-if="isUserLoaded"
          :loading="loading"
          size="large"
          full-width-desktop
          text="подтвердить смену по смс"
          class="mt-5"
          @click="changePassword"
        />
      </div>
    </div>

    <ModalConfirmSMS
      v-if="isShowSmsModal"
      :loading="loading"
      :confirm-function="confirmChangePasswordAsync"
      :request="changePassword"
      :confirm-message="null"
      :error-message="confirmErrorMessage"
      title="подтвердите смену пароля"
      @close="isShowSmsModal = false"
    />
  </section>
</template>
<script setup>
import { ref, computed, watch, onMounted, nextTick } from "vue";
import { useRouter } from 'vue-router';
import server from '@/server';
import SwitcherSlider from '@/components/_generic/cselect/SwitcherSlider.vue';
import { useStateStore } from '@/store/stateStore';
import FormFieldPasspord from '@/components/_generic/forms/FormFieldPasspord.vue';
import validators from "@/validators";
import ModalConfirmSMS from "@/components/_generic/modals/ModalConfirmSMS";

const store = useStateStore();
const router = useRouter();

const loading = ref(false);
const localRawPassword = ref(null);
const errorMessagePassword = ref(null);

const isShowSmsModal = ref(false);
const confirmErrorMessage = ref('');
const smsCode = ref(null);

const isOtpEnabled = ref(false);

const isUserLoaded = computed(() => {
  return store.isUserLoaded;
});
const user = computed(() => {
  return store.state.user;
});

watch(
  () => {
    return user.value.isOtpEnabled;
  },
  () => {
    isOtpEnabled.value = user.value.isOtpEnabled;
  },
);

onMounted(() => {
  isOtpEnabled.value = user.value.isOtpEnabled;
});

const pressKeyPassword = () => {
  errorMessagePassword.value = null;
};

const changePassword = () => {
  if (!isPasswordValid()) {
    return;
  }

  requestChangePasswordAsync();
};

const isPasswordValid = () => {
  if (!localRawPassword.value || localRawPassword.value.length < 6) {
    errorMessagePassword.value = 'Минимальная длина пароля 6 символов';
    return false;
  }
  if (localRawPassword.value.length > 32) {
    errorMessagePassword.value = 'Максимальная длина пароля 32 символов';
    return false;
  }
  if (!validators.passwordAlpha.$validator(localRawPassword.value)) {
    errorMessagePassword.value =
      'Пароль должен состоять из строчных и/или заглавных букв латинского алфавита (A—Z), цифр и знаков препинания.';
    return false;
  }
  return true;
};

const requestChangePasswordAsync = () => {
  loading.value = true;

  const query = {
    phone: user.value.login,
  };
  return server.postUsersRecoverPassRequest
    .send(query)
    .pipe(onRequestChangePasswordAsyncSuccess.bind(this, query), () => loading.value = false)
    .exec();
};
const onRequestChangePasswordAsyncSuccess = (query, { data }) => {
  loading.value = false;
  isShowSmsModal.value = data.isSuccessful;
  confirmErrorMessage.value = null;
};

const confirmChangePasswordAsync = ({ code }) => {
  const query = {
    phone: user.value.login,
    smsCode: code,
    newPassword: localRawPassword.value,
  };

  loading.value = true;

  return server.postUsersRecoverPassConfirm
    .send(query)
    .pipe(onConfirmChangePasswordAsyncSuccess.bind(this, query), () => loading.value = false)
    .exec();
};
const onConfirmChangePasswordAsyncSuccess = (query, { data }) => {
  loading.value = false;

  if (data.isSuccessful) {
    store.merge({
      session: {
        login: query.phone,
        token: data.token,
      },
    });

    isShowSmsModal.value = false;
    localRawPassword.value = null;
    errorMessagePassword.value = null;

    store.setNotification({
      message: 'Вы успешно изменили пароль',
    })
  } else {
    confirmErrorMessage.value = 'неверный код'
  }
};

const putUsersMeProfileAsync = () => {
  store.setUserIsOtpEnabled(isOtpEnabled.value);

  loading.value = true;
  const query = {
    isOtpEnabled: user.value.isOtpEnabled,
  };
  return server.putUsersMeProfile
    .send(query)
    .pipe(onPutUsersMeProfileAsyncSuccess.bind(this, query), () => {
      loading.value = false;
    })
    .exec();
};
const onPutUsersMeProfileAsyncSuccess = () => {
  loading.value = false;
};


</script>

<style lang="scss" scoped>
@import "src/scss/typography.scss";

.security-auth {
  @media (max-width: $size_767) {
    padding: 18px 14px;
  }
}

.block-title {
  @media (max-width: $size_767) {
    display: none;
  }
}

.security-auth-top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: $size_767) {
    flex-wrap: nowrap;
    align-items: flex-start;
    @include potok-text-body-1;
  }
}
.security-text-link {
  text-decoration: none;
}
.security-password-wrapper {
  max-width: 340px;
}
.settings_row_desc {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 18px;
  padding: 0;
  color: #7d7d7e;
  @media (max-width: $size_767) {
    font-size: 14px;
    line-height: 20px;
  }
}
.settings_row_desc {
  margin-right: 50px;
  @media (max-width: $size_767) {
    margin-right: 0px;
  }
}
</style>
