import constants from '@/constants';
import server from '@/server';

export default {
  beforeCreate() {
    this.constants = constants;
  },
  data() {
    return {
      constants,
      server,
    };
  },
};
