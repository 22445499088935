<template>
  <VDropdown>
    <button class="info-icon-hint project__tag collateral">
      <PotokImg
        :src="imageConstants.attention"
        :height="isTablet ? 20 : 14"
        :width="isTablet ? 20 : 14"
        class="mr-2"
      />
    </button>
    <template #popper>
      <div class="popup">
        <div v-close-popper class="popup-close"></div>
        <div class="popup-text">
          <slot></slot>
        </div>
      </div>
    </template>
  </VDropdown>
</template>
<script setup>
import imageConstants from '@/constants/imageConstants';
import detectDevice from '@/composables/detectDeviceComposable';

const { isTablet } = detectDevice();
</script>
<style lang="scss" scoped></style>
