export default {
  getInvestment: {
    url: `/api/investments/:id`,
    method: 'GET',
  },
  getRiskFreeStrategyRate: {
    url: `/api/strategies/no-risk`,
    method: 'GET',
  },
  getInvestmentsSold: {
    url: `api/investments/potok/sold`,
    method: 'GET',
  },
  getInvestmentsSellList: {
    url: '/api/admin/investments/investor-buybacks',
    method: 'GET',
  },
  getInvestmentsSellListNotAdmin: {
    url: '/api/investments/investor-buybacks',
    method: 'GET',
  },
  postInvestmentsSellListApproveAll: {
    url: '/api/admin/investments/investor-buybacks/approve-all',
    method: 'POST',
  },
  getInvestmentsSellItem: {
    url: '/api/investments/investor-buybacks/:id',
    method: 'GET',
  },
  putInvestmentsSellItemByOperator: {
    url: '/api/admin/investments/investor-buybacks/:id',
    method: 'PUT',
    body: {
      status: null,
    },
  },
  putInvestmentsSellItem: {
    url: '/api/investments/investor-buybacks/:id/cancel?companyId=:companyId',
    method: 'PUT',
  },
  getInvestmentsForSale: {
    url: '/api/investments/investor-buybacks/for-sale-request',
    method: 'GET',
  },
  postInvestmentsSellRequest: {
    url: '/api/investments/investor-buybacks/request?companyId=:companyId',
    method: 'POST',
    body: {
      discountPercentage: 0,
    },
  },
  postInvestmentsSellRequestConfirm: {
    url: '/api/investments/investor-buybacks/confirm?companyId=:companyId',
    method: 'POST',
    body: {
      smsId: '',
      code: '',
    },
  },
  getInvestmentsAmountTotal: {
    url: '/api/admin/investments/investor-buybacks/total',
    method: 'GET',
  },
  postInvestmentsBuybacksTour: {
    url: '/api/users/tours/investor-buybacks/complete',
    method: 'POST',
  },
  getInvestmentsTourStatus: {
    url: '/api/users/tours',
    method: 'GET',
  },
};
