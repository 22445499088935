<template>
  <div>
    <ModalFull :title="'Фильтры'" @close="close">
      <template #content>
        <div class="block-menu-mobile-item">
          <div class="projects-filters">
            <div class="filter_item">
              <MultiSelect
                :items="riskTypes"
                :placeholder="'Тип риска'"
                @change="onChangeFilters"
              >
              </MultiSelect>
            </div>
            <div class="filter_item">
              <MultiSelect
                :items="loanTypes"
                :placeholder="'Тип займа'"
                @change="onChangeFilters"
              >
              </MultiSelect>
            </div>
            <div class="filter_item">
              <MultiSelect
                :items="borrowTypes"
                :placeholder="'Тип заемщиков'"
                @change="onChangeFilters"
              ></MultiSelect>
            </div>
            <div class="filter_item">
              <CSelect
                v-model="selectedSortingOptions"
                :items="sortingOptions"
                @update:model-value="onChangeSortingOptions"
              />
            </div>
          </div>
        </div>
      </template>
      <template #actions>
        <div class="actions_wrapper">
          <button
            type="button"
            class="button button-secondary-outline"
            @click="onClickApplyFilters"
          >
            Применить
          </button>
          <button
            type="button"
            class="button button-white"
            @click="onClickResetFilters"
          >
            Сбросить
          </button>
        </div>
      </template>
    </ModalFull>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue';

import MultiSelect from '@/components/_generic/cselect/MultiSelect.vue';
import { CSelect } from 'potok-uikit';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';
import loansFiltersComposable from '@/composables/loansFiltersComposable';
import loansItemsSortComposable from '@/composables/loansItemsSortComposable';

const emit = defineEmits([
  'close',
  'changeSelectedSortingOptions',
  'changeFilters',
]);

const {
  riskTypes,
  loanTypes,
  borrowTypes,
  updateFilters,
  resetFilters,
  initFilterData,
  filters,
} = loansFiltersComposable();

const {
  sortingOptions,
  selectedSortingOptions,
  updateSortingOptions,
  initSortingData,
} = loansItemsSortComposable();

const close = () => {
  emit('close');
};
const onClickApplyFilters = () => {
  updateFilters();
  updateSortingOptions(selectedSortingOptions.value);
  emit('changeFilters', filters.value);
  emit('changeSelectedSortingOptions', selectedSortingOptions.value);
  emit('close');
};
const onClickResetFilters = () => {
  resetFilters(filters.value);
  updateSortingOptions('default');
  emit('changeFilters', filters.value);
  emit('changeSelectedSortingOptions', selectedSortingOptions.value);
  emit('close');
};

const onChangeFilters = () => {};

const onChangeSortingOptions = (value) => {
  selectedSortingOptions.value = value;
};

onMounted(() => {
  document.body.style.overflow = 'hidden';
  initFilterData();
  initSortingData();
});

onUnmounted(() => {
  document.body.style.overflow = null;
});
</script>
<style lang="scss" scoped>
.button {
  @media (max-width: $size_767) {
    width: 100%;
  }
}
.button-white {
  margin-top: 10px;
}
.filter_item {
  margin: 16px 0;
}

.actions_wrapper {
  display: flex;
  button {
    margin: 0 14px;
    height: 36px;
  }
}
</style>
