<template>
  <div class="wrapper__content wrapper__content__has_background">
    <div class="wrapper__header">
      <div class="wrapper__header__left"></div>
      <header class="header header__container">
        <component :is="headerComponentName"></component>
      </header>
      <div class="wrapper__header__right"></div>
    </div>
    <main>
      <router-view name="content"></router-view>
    </main>
    <Footer />

    <tips-handler></tips-handler>

    <DebugTeleport v-if="isRunTeleport" />

    <UiNotification
      :timer="notificationData.timer"
      :title="notificationData.title"
      :message="notificationData.message"
      :is-error="notificationData.isError"
    />
    <ModalRefferalMessage v-if="isShowModalRefferal" />
    <TelegramDialog
      v-if="
        !store.state.loaders.isUserLoading && store.state.user.isShowTgBanner
      "
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { parseISO } from 'date-fns';

import TipsHandler from '@/components/_generic/modals/TipsHandler.vue';
import ModalRefferalMessage from '@/components/_generic/modals/ModalRefferalMessage.vue';
import UiNotification from '@/components/_generic/notification/UiNotification.vue';

import Footer from '@/components/Footer/Footer.vue';
import HeaderUnauth from '@/components/Header/HeaderUnauth.vue';
import Header from '@/components/Header/Header.vue';
import DebugTeleport from '@/components/Teleport/Teleport.vue';

import { useStateStore } from '@/store/stateStore';
import TelegramDialog from '@/components/dialogs/TelegramDialog.vue';
import server from "@/server";
import { formatDateInTimeZone, isAvailableBetweenDates } from "@/utils/date/dateUtils";

const store = useStateStore();
const isRunTeleport = ref(false);

const headerComponentName = computed(() => {
  return store.isUnauthenticated ? HeaderUnauth : Header;
});
const isShowModalRefferal = computed(() => {
  return store.state.modals.isShowModalRefferal;
});
const notificationData = computed(() => {
  return store.state.notification;
});

if (process.env.VUE_APP_NODE_ENV === 'mock') {
  isRunTeleport.value = true;
}

const getFeaturesAsync = () => {
  return server.getFeatures
    .send()
    .pipe(onGetFeaturesAsyncSuccess, () => { store.state.loaders.isFeaturesLoading = false; })
    .exec();
}

const onGetFeaturesAsyncSuccess = ({ data }) => {
  store.state.features.general = data
  store.state.loaders.isFeaturesLoading = false;
}

getFeaturesAsync();

onMounted(() => {
  store.setNotification({
    title: '',
    message: '',
  })

  const now = new Date();
  const nowInMoscow = formatDateInTimeZone(now, 'Europe/Moscow', 'yyyy-MM-dd HH:mm:ss');
  const nowInMoscowDate = parseISO(nowInMoscow);

  const isLiveNow = isAvailableBetweenDates(nowInMoscowDate, '2024-09-16 00:00:00', '2024-09-26 00:00:00');

  if (isLiveNow) {
    setTimeout(() => {
      store.setNotification({
        title: 'внимание акция!',
        message: 'Пополните счет до 25 сентября и получите бонус до 15 000 руб! <br><br> <a href="https://files.potok.digital/Документы/раздел правила платформы/Акция_Инвестор в плюсе 8.0.pdf" target="_blank">подробнее →</a>',
        timer: 15000,
        isError: false,
      })
    }, 1000)
  }
})
</script>

<style lang="scss"></style>
