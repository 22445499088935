<template>
  <ModalFull
    :title="''"
    :close-click-outside="false"
    @close="emit('close')"
  >
    <template #content>
      <div>
        для ИП и ООО на упрощенной системе налогообложения: сумма, полученная от продажи инвестиций,
        подлежит включению в состав доходов в ПОЛНОМ ОБЪЕМЕ согласно пункту 1 статьи 346.15 и пункту 1 статьи 249 НК РФ.
        Данная информация не является налоговой консультацией. Рекомендуем обращаться к помощи налогового консультанта.
      </div>
    </template>
    <template #actions>
      <PotokButton
        text="продолжить"
        @click="onClickConfirm"
      />
      <PotokButton
        class="btn-cancel"
        text="назад"
        theme="tertiary"
        @click="onClickCancel"
      />
    </template>
  </ModalFull>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';

const emit = defineEmits(['close', 'confirm']);

const onClickConfirm = () => {
  emit('confirm')
};
const onClickCancel = () => {
  emit('close');
};
</script>
<style lang="scss" scoped>
:deep(.modal_block_container) {
  max-width: 420px;
}
:deep(.modal_block_content) {
  text-align: center;
}
:deep(.modal_block_title) {
  justify-content: center;
}
:deep(.modal_block_actions) {
  margin: 20px auto 0;
  display: flex;
  @media (max-width: $size_767) {
    display: block;
    width: 100%;
  }
}
.btn-cancel {
  margin-left: 10px;
  @media (max-width: $size_767) {
    margin-left: initial;
    margin-top: 10px;
  }
}
</style>
