<template>
  <div class="field-container">
    <div class="item-devider" />
    <div
      class="field"
      :class="{'on-hover': !modeEditEmail}"
      @click="onClickEditEmail"
    >
      <div class="item-info">
        <div class="item-name potok-text-uppercase">Почта</div>
        <div class="item-value mt-1">
          <template v-if="!modeEditEmail">
            <span v-if="props.user.email">{{ props.user.email }}</span>
            <span v-if="!props.user.email">нет почты</span>
          </template>
          <template v-if="modeEditEmail">
            <div class="item-edit mt-1">
              <input
                v-model="editedEmail"
                class="field-input potok-text-body-1 mr-2"
                :class="{
                  'potok-color-red': v$.editedEmail.$dirty && v$.editedEmail.$invalid,
                }"
                type="email"
                @keyup="v$.editedEmail.$touch()"
              />
              <PotokButton
                text="сохранить"
                no-full-width-tablet
                :loading="loading"
                :disabled="v$.editedEmail.$invalid"
                class="save-btn mr-2"
                @click.stop="onClickSaveEmail"
              />
              <PotokButton
                :disabled="loading"
                no-full-width-tablet
                text="отмена"
                theme="tertiary"
                @click.stop="onClickCancelEmail"
              />
            </div>
          </template>
        </div>
      </div>
      <template v-if="!modeEditEmail">
        <PotokImg
          v-if="props.user.email && !isMobile"
          height="20"
          width="20"
          class="on-hover-only"
          :src="imageConstants.pencil"
        />
        <PotokButton
          v-if="!props.user.email && !isMobile"
          size="link"
          theme="linkIcon"
          text="добавить"
          class="on-hover-only tiffany"
          @click="onClickEditEmail"
        >
          <template #icon-after>
            <PotokImg
              height="7"
              width="10"
              class="ml-1"
              :src="imageConstants.arrowRightTiffany"
            />
          </template>
        </PotokButton>
        <PotokImg
          v-if="isMobile"
          height="7"
          width="16"
          :src="imageConstants.arrowRightDeepdiveLong"
        />
      </template>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import detectDevice from '@/composables/detectDeviceComposable.js';
import useVuelidate from '@vuelidate/core';
import validators from '@/validators';
import server from '@/server';
import imageConstants from "@/constants/imageConstants";
import { useStateStore } from '@/store/stateStore';

const { isMobile } = detectDevice();
const store = useStateStore();

const props = defineProps(['user']);

const loading = ref(false);
const modeEditEmail = ref(false);
const editedEmail = ref(null);

const validationsRules = {
  editedEmail: {
    required: validators.required,
    email: validators.email,
  },
};
const v$ = useVuelidate(validationsRules, {
  editedEmail,
});
const onClickEditEmail = () => {
  editedEmail.value = props.user.email;
  modeEditEmail.value = true;
};
const onClickCancelEmail = () => {
  editedEmail.value = null;
  modeEditEmail.value = false;
};
const onClickSaveEmail = () => {
  const query = {
    email: editedEmail.value,
  };
  loading.value = true;
  return server.putUsersMeProfile
    .send(query)
    .pipe(
      onClickSaveEmailAsyncSuccess.bind(this, query),
      onClickSaveEmailAsyncError.bind(this),
    )
    .exec();
};
const onClickSaveEmailAsyncSuccess = () => {
  store.setUserEmail(this.editedEmail);
  loading.value = false;
  modeEditEmail.value = false;
};
const onClickSaveEmailAsyncError = () => {
  loading.value = false;
  modeEditEmail.value = false;
};
</script>
<style lang="scss" scoped>
.field {
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: $size_767) {
    padding: 16px 0;
  }
}
.on-hover {
  &:hover {
    border-radius: 14px;
    background: $background;
    cursor: pointer;
    .on-hover-only {
      display: block;
    }
  }
}
.item-name {
  color: $grey;
}
.item-devider {
  margin: 0 auto;
  height: 1px;
  width: calc(100% - 40px);
  background: $dividers;
  @media (max-width: $size_767) {
    width: calc(100% - 32px);
  }
}
.on-hover-only {
  display: none;
}
.item-edit {
  display: flex;
  @media (max-width: $size_767) {
    flex-direction: column;
  }
}
.item-info {
  width: 100%;
}
.field-input {
  padding: 10px;
  border-radius: 8px;
  border: none;
  background: $background;
  @media (max-width: $size_767) {
    margin-bottom: 16px;
    margin-right: 0;
  }
}
.tiffany {
  color: $tiffany;
}
.save-btn {
  @media (max-width: $size_767) {
    margin-bottom: 16px;
    margin-right: 0;
  }
}
</style>
