<template>
  <div class="banner-2kk">
    <p class="banner-2kk-text potok-text-h2-light no-text-transform">
      инвестируйте в компанию группы Поток на особых условиях
      <span class="banner-2kk-color-text">от 2 000 000 ₽</span>
    </p>

    <PotokButton
      :text="isTablet ? 'оставить заявку →' : 'оставить заявку'"
      :theme="isTablet ? 'linkIcon' : 'tertiary'"
      :size="isTablet ? 'link' : 'medium'"
      no-full-width-tablet
      class="banner-2kk-btn"
      @click="goToLink"
    />
  </div>
</template>

<script setup>
import detectDevice from '@/composables/detectDeviceComposable';
import { PotokButton } from 'potok-uikit';

const { isTablet } = detectDevice();

const goToLink = () => {
  window.open(
    'https://docs.google.com/forms/d/e/1FAIpQLSc6eGHa8E_TEzI_hzj46Ypl7MW58on9hZXdrhod1tXN6ZdLBg/viewform',
    '_blank',
  );
};
</script>

<style scoped lang="scss">
@import 'src/scss/typography.scss';
.banner-2kk {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
  border-radius: 8px;
  background-color: $breakwater;
  background-image: url('../../../assets/img/banner-2kk-desktop.png');
  background-repeat: no-repeat;

  @media (max-width: $size_991) {
    flex-direction: column;
    align-items: flex-start;
    background-image: url('../../../assets/img/banner-2kk-mobile.png');
    background-size: contain;
    background-position-x: 100%;
  }
}
.banner-2kk-text {
  width: 405px;
  margin: 0 0 0 180px;
  padding: 0;
  color: $white;
  @media (max-width: $size_991) {
    @include potok-text-body-2;
    width: 100%;
    padding-right: 90px;
    margin: 0 0 0 0;
  }
}
.banner-2kk-color-text {
  color: $tiffany;
}
.banner-2kk-btn {
  color: $white;
  @media (max-width: $size_991) {
    @include potok-text-body-2;
    margin-top: 20px;
  }
  &:hover {
    color: $breakwater;
    @media (max-width: $size_991) {
      color: $white;
    }
  }
}
</style>
