<template>
  <div v-if="!loading">
    <div class="dashboard-invest-loans-banner">
      <div class="dashboard-invest-loans-banner-content">
        <div class="dashboard-invest-loans-banner-text-container">
          <div
            class="dashboard-invest-loans-banner-title potok-text-h2"
            v-html="texts.offerTitle"
          />
          <div class="dashboard-invest-loans-banner-text mt-2 potok-text-body-1">
            {{ texts.offerDescription }}
          </div>
        </div>
        <PotokButton
          :text="texts.applicationText"
          theme="linkIconUpload"
          size="link"
          noFullWidthMobile
          class="dashboard-invest-loans-banner-btn"
          @click="toLink"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { PotokButton } from 'potok-uikit';
import server from '@/server';

const loading = ref(true);
const texts = ref({
  offerTitle: '',
  offerDescription: '',
  applicationText: '',
  applicationLink: '',
});

const toLink = () => {
  window.open(texts.value.applicationLink, '_blank');
};

const getInvestingPotokLoansBanner = () => {
  server.getInvestingPotokLoansBanner
    .send()
    .pipe((data) => {
      texts.value = data.data;
      loading.value = false;
    }, () => loading.value = false)
    .exec();
}

const init = () => {
  getInvestingPotokLoansBanner();
};
init();
</script>

<style scoped lang="scss">
@import 'scss/typography.scss';

.dashboard-invest-loans-banner {
  width: 100%;
  padding: 25px 30px 25px 30px;
  background-color: $dividers;
  border-radius: 14px;
  background-image: url('@/assets/img/banners/investLoanBannerDesk.png');
  background-repeat: no-repeat;

  @media (max-width: $size_991) {
    padding: 16px 24px 20px 20px;
    background-image: url('@/assets/img/banners/investLoanBannerMobile.png');
    background-position-x: right;
    background-position-y: bottom;
  }
}
.dashboard-invest-loans-banner-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 135px;

  @media (max-width: $size_991) {
    margin-left: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.dashboard-invest-loans-banner-text-container {
  display: flex;
  flex-direction: column;
}
.dashboard-invest-loans-banner-title {
  @media (max-width: $size_991) {
    @include potok-text-body-2;
    font-weight: 500;
    letter-spacing: -0.01em;
  }
}
.dashboard-invest-loans-banner-text {
  color: $grey;
  @media (max-width: $size_991) {
    @include potok-text-body-2;
  }
}
.dashboard-invest-loans-banner-text-special {
  color: $tiffany;
}
.dashboard-invest-loans-banner-btn {
  flex-shrink: 0;
  @media (max-width: $size_991) {
    margin-top: 20px;
  }
}
</style>