const thirdPartyServicesStatuses = {
  notTouched: 'NOTTOUCHED',
  inProgress: 'INPROGRESS',
  fail: 'FAIL',
  error: 'ERROR',
  success: 'SUCCESS',
};

const POTOK_FINANCE_ID = 'cf4d7435-c289-4df7-93d4-b8f804354ea5';

const PROJECT__STATUS__CHECKING = 'aa1272f5-e4ba-40d1-af7e-64413f9c4d7f';
const PROJECT__STATUS__NEED_PASPORT = 'bc777391-59ce-4990-b0dc-5eb03be1e3dd';
const PROJECT__STATUS__DECLINED = '0118217a-aa2e-4a04-9539-55095e2f4a9b';
const PROJECT__STATUS__APPROVED = 'a14fce84-311b-4320-a9a4-b60fd6b2851f';
const PROJECT__STATUS__CANCELED_BEFORE_FUNDS_START =
  'e07c5509-73a1-45b2-b845-4bd0c91a9532';
const PROJECT__STATUS__CANCELED = '47d74be5-dde6-4532-82b8-707d8226537c';
const PROJECT__STATUS__RISING_FUNDS = '6cc5e545-e34a-4900-ad25-20bf3223e765';
const PROJECT__STATUS__RISING_TIMEOUT = '02958d5d-d241-45f6-84d6-fb917254a25d';
const PROJECT__STATUS__FUNDED = '40ce4cd6-518f-4fdf-baba-46e925243baf';
const PROJECT__STATUS__OVERDUE = '3a8199d2-40ed-4f57-bc8f-29b0bb3fe79d';
const PROJECT__STATUS__REFUNDED = 'f739d9ff-a5d5-402a-b0c9-21c781db910c';
const PROJECT__STATUS__CESSION_1ST = 'bdae9d2e-37b6-4789-8bb1-12e49c678741';
const PROJECT__STATUS__CESSION_2ND = 'c2aece14-c09c-4bec-b2a8-a207229687ba';
const PROJECT__STATUS__CESSION_FIXED = '74e423ac-4af1-46ce-a9b9-3726510a7a09';
const PROJECT__STATUS__APPROVED_WAIT_OPTION =
  'f8dd96e5-b37b-48fc-b04d-5697a917afde';
const PROJECT__STATUS__ISSUING = 'fd5674c3-d8a5-4712-98c6-33d7df86bc59';

const projectStatusSlug = {
  [PROJECT__STATUS__CHECKING]: 'checking',
  [PROJECT__STATUS__NEED_PASPORT]: 'needPassport',
  [PROJECT__STATUS__DECLINED]: 'declined',
  [PROJECT__STATUS__APPROVED]: 'approved',
  [PROJECT__STATUS__CANCELED_BEFORE_FUNDS_START]: 'canceledBeforeFundsStart',
  [PROJECT__STATUS__CANCELED]: 'canceled',
  [PROJECT__STATUS__RISING_FUNDS]: 'risingFunds',
  [PROJECT__STATUS__RISING_TIMEOUT]: 'risingTimeout',
  [PROJECT__STATUS__FUNDED]: 'funded',
  [PROJECT__STATUS__OVERDUE]: 'overdue',
  [PROJECT__STATUS__REFUNDED]: 'refunded',
  [PROJECT__STATUS__CESSION_1ST]: 'cession1st',
  [PROJECT__STATUS__CESSION_2ND]: 'cession2nd',
  [PROJECT__STATUS__CESSION_FIXED]: 'cessionFixed',
  [PROJECT__STATUS__APPROVED_WAIT_OPTION]: 'approvedWaitOption',
  [PROJECT__STATUS__ISSUING]: 'issuing',
};

const projectStatusTitle = {
  [PROJECT__STATUS__CHECKING]: 'проверка',
  [PROJECT__STATUS__NEED_PASPORT]: 'ожидание паспорта',
  [PROJECT__STATUS__DECLINED]: 'отказан',
  [PROJECT__STATUS__APPROVED]: 'одобрен',
  [PROJECT__STATUS__CANCELED_BEFORE_FUNDS_START]:
    'отменен заемщиком до начала сбора средств',
  [PROJECT__STATUS__CANCELED]: 'отменен',
  [PROJECT__STATUS__RISING_FUNDS]: 'сбор денег',
  [PROJECT__STATUS__RISING_TIMEOUT]: 'срок сбора истек',
  [PROJECT__STATUS__FUNDED]: 'выдан',
  [PROJECT__STATUS__OVERDUE]: 'просрочен',
  [PROJECT__STATUS__REFUNDED]: 'возвращен',
  [PROJECT__STATUS__CESSION_1ST]: 'цессия - 1 этап',
  [PROJECT__STATUS__CESSION_2ND]: 'цессия - 2 этап',
  [PROJECT__STATUS__CESSION_FIXED]: 'цессия - фикс выплаты',
  [PROJECT__STATUS__APPROVED_WAIT_OPTION]: 'одобрено: ожидание опциона',
  [PROJECT__STATUS__ISSUING]: 'выдается',
};
const projectStatusClass = {
  [PROJECT__STATUS__CHECKING]: 'project-check',
  [PROJECT__STATUS__NEED_PASPORT]: 'project-wait',
  [PROJECT__STATUS__DECLINED]: 'project-overdue',
  [PROJECT__STATUS__APPROVED]: 'project-active',
  [PROJECT__STATUS__CANCELED_BEFORE_FUNDS_START]: 'project-wait',
  [PROJECT__STATUS__CANCELED]: 'project-rejected',
  [PROJECT__STATUS__RISING_FUNDS]: 'project-rising-funds',
  [PROJECT__STATUS__RISING_TIMEOUT]: 'project-rising-funds',
  [PROJECT__STATUS__FUNDED]: 'project-funded',
  [PROJECT__STATUS__OVERDUE]: 'project-overdue',
  [PROJECT__STATUS__REFUNDED]: 'project-closed',
  [PROJECT__STATUS__CESSION_1ST]: 'project-overdue',
  [PROJECT__STATUS__CESSION_2ND]: 'project-overdue',
  [PROJECT__STATUS__CESSION_FIXED]: 'project-overdue',
  [PROJECT__STATUS__ISSUING]: 'project-issuing',
};

const COMPANY_TYPES_PERSON = '1257b3cb-58f4-4418-b4c0-9e69e0baa0dc';
const COMPANY_TYPES_ENTREPRENEUR = '51084693-068e-4733-8ad9-795a40c9cb1c';
const COMPANY_TYPES_COMPANY = '4092b6a8-409a-45f5-a64c-122a3f2a5ecf';

export default {
  PROJECT__STATUS__CHECKING,
  PROJECT__STATUS__NEED_PASPORT,
  PROJECT__STATUS__DECLINED,
  PROJECT__STATUS__APPROVED,
  PROJECT__STATUS__CANCELED_BEFORE_FUNDS_START,
  PROJECT__STATUS__CANCELED,
  PROJECT__STATUS__RISING_FUNDS,
  PROJECT__STATUS__RISING_TIMEOUT,
  PROJECT__STATUS__FUNDED,
  PROJECT__STATUS__OVERDUE,
  PROJECT__STATUS__REFUNDED,
  PROJECT__STATUS__CESSION_1ST,
  PROJECT__STATUS__CESSION_2ND,
  PROJECT__STATUS__CESSION_FIXED,
  PROJECT__STATUS__APPROVED_WAIT_OPTION,
  PROJECT__STATUS__ISSUING,
  COMPANY_TYPES_PERSON,
  COMPANY_TYPES_ENTREPRENEUR,
  COMPANY_TYPES_COMPANY,
  POTOK_FINANCE_ID,
  defaultTitle: 'Поток | Инвестиции в малый и средний бизнес',
  defaultTitlePostfix: ' — Кабинет инвестора | Поток',
  // We should load these types from the backed
  // @see https://gitlab.mmdev.ru/modulemoney/module-money-backend-gateway/blob/master/src/constants.js#L2
  companyTypes: {
    person: COMPANY_TYPES_PERSON,
    entrepreneur: COMPANY_TYPES_ENTREPRENEUR,
    company: COMPANY_TYPES_COMPANY,
  },
  depositOperations: {
    investment: 1,
    refill: 2,
    signingEula: 3,
    project: 4, // Операция просмотра займа
  },
  thirdPartyServicesStatuses,
  tenderLaw: {
    fourtyFourthFederalLaw: '44-ФЗ',
    twoHundredTwentyThrirdFederalLaw: '223-ФЗ',
    oneHundredEightyFifthFederalLaw: '185-ФЗ',
    sixHundredFifthGovernmentResolution: '615-П',
  },
  // @see https://gitlab.mmdev.ru/modulemoney/module-money-backend-gateway/blob/master/src/constants.js#L117
  projectStatus: {
    checking: PROJECT__STATUS__CHECKING,
    needPassport: PROJECT__STATUS__NEED_PASPORT,
    declined: PROJECT__STATUS__DECLINED,
    approved: PROJECT__STATUS__APPROVED,
    canceledBeforeFundsStart: PROJECT__STATUS__CANCELED_BEFORE_FUNDS_START, // will be hidden from anyone, but owner & admin
    canceled: PROJECT__STATUS__CANCELED,
    risingFunds: PROJECT__STATUS__RISING_FUNDS,
    risingTimeout: PROJECT__STATUS__RISING_TIMEOUT,
    funded: PROJECT__STATUS__FUNDED,
    overdue: PROJECT__STATUS__OVERDUE,
    refunded: PROJECT__STATUS__REFUNDED,
    cession1st: PROJECT__STATUS__CESSION_1ST,
    cession2nd: PROJECT__STATUS__CESSION_2ND,
    cessionFixed: PROJECT__STATUS__CESSION_FIXED,
    approvedWaitOption: PROJECT__STATUS__APPROVED_WAIT_OPTION,
    issuing: PROJECT__STATUS__ISSUING,
  },
  projectStatusSlug,
  projectStatusTitle,
  projectStatusClass,
  clientTypes: {
    investor: 'investor',
    borrower: 'borrower',
  },
  documentTypes: {
    LOAN: 'LOAN',
    INVESTMENT: 'INVESTMENT',
    LOAN_ACCEPTANCE: 'LOAN_ACCEPTANCE',
    CESSION_CONTRACT: 'CESSION_CONTRACT',
    JOIN_EULA: 'JOIN_EULA',
    PASSPORT_PAGE_MAIN: 'PASSPORT_PAGE_MAIN',
    PASSPORT_PAGE_REGISTRATION: 'PASSPORT_PAGE_REGISTRATION',
    UNKNOWN: 'UNKNOWN',
    QI_DOCUMENT: 'QI_DOCUMENT',
    QI_CONFIRM: 'QI_CONFIRM',
    QI_NOT_QI: 'QI_NOT_QI',
    ENABLE_AUTO_INVEST_STRATEGY_ALFA_BASIC: 'ENABLE_AUTO_INVEST_STRATEGY_ALFA_BASIC',
    SECONDARY_MARKET_CESSION_AGREEMENT_OFFER:
      'SECONDARY_MARKET_CESSION_AGREEMENT_OFFER',
    SECONDARY_MARKET_ACCEPT_CESSION_AGREEMENT_OFFER:
      'SECONDARY_MARKET_ACCEPT_CESSION_AGREEMENT_OFFER',
    SECONDARY_MARKET_STATEMENT_FROM_AGREEMENTS_REGISTER:
      'SECONDARY_MARKET_STATEMENT_FROM_AGREEMENTS_REGISTER',
    SECONDARY_MARKET_BUYBACK_NOTIFICATION:
      'SECONDARY_MARKET_BUYBACK_NOTIFICATION',
    ENABLE_DEFAULT_AUTO_INVEST_SETTINGS: 'ENABLE_DEFAULT_AUTO_INVEST_SETTINGS',
    ENABLE_PERSONAL_AUTO_INVEST_SETTINGS:
      'ENABLE_PERSONAL_AUTO_INVEST_SETTINGS',
    DISABLE_AUTO_INVEST: 'DISABLE_AUTO_INVEST',
    ENABLE_AUTO_INVEST_STRATEGY_ALFA_LIQUID:
      'ENABLE_AUTO_INVEST_STRATEGY_ALFA_LIQUID',
    ENABLE_AUTO_INVEST_STRATEGY_LIQUID_FAIR_RATE:
      'ENABLE_AUTO_INVEST_STRATEGY_LIQUID_FAIR_RATE',
    ENABLE_AUTO_INVEST_STRATEGY_FIXED_FAIR_RATE:
      'ENABLE_AUTO_INVEST_STRATEGY_FIXED_FAIR_RATE',
    ENABLE_AUTO_INVEST_STRATEGY_WITHOUT_RISK:
      'ENABLE_AUTO_INVEST_STRATEGY_WITHOUT_RISK',
    ENABLE_AUTO_INVEST_STRATEGY_CONSERVATIVE:
      'ENABLE_AUTO_INVEST_STRATEGY_CONSERVATIVE',
    ENABLE_AUTO_INVEST_STRATEGY_AGGRESSIVE:
      'ENABLE_AUTO_INVEST_STRATEGY_AGGRESSIVE',
    ENABLE_AUTO_INVEST_STRATEGY_ALFA_CONSERVATIVE:
      'ENABLE_AUTO_INVEST_STRATEGY_ALFA_CONSERVATIVE',
    DEFENSE: 'DEFENSE',
    OVERDUE_CHECKLIST: 'OVERDUE_CHECKLIST',
    RECEIPT: 'RECEIPT',
    SPECIAL_INVESTOR_LOANS: 'SPECIAL_INVESTOR_LOANS',
    SPECIAL_INVESTOR_CESSION: 'SPECIAL_INVESTOR_CESSION',
    CESSION_OFFER_PORTFOLIO_SALE_WITH_DISCOUNT:
      'CESSION_OFFER_PORTFOLIO_SALE_WITH_DISCOUNT',
  },
  documentTypesTitle: {
    LOAN: `Инвестиционное предложение`,
    DEFENSE: `Договор защиты`,
    INVESTMENT: `Акцепт инвестиционного предложения`,
    LOAN_ACCEPTANCE: `Индивидуальные условия договора займа`,
    CESSION_CONTRACT: `Договор цессии`,
    OVERDUE_CHECKLIST: `Договор поручительства`,
    JOIN_EULA: `Заявление на Правила площадки`,
    SECONDARY_MARKET_CESSION_AGREEMENT_OFFER: `Оферта`,
    SECONDARY_MARKET_ACCEPT_CESSION_AGREEMENT_OFFER: `Акцепт оферты`,
    SECONDARY_MARKET_STATEMENT_FROM_AGREEMENTS_REGISTER: `Выписка из реестра`,
    SECONDARY_MARKET_BUYBACK_NOTIFICATION: 'Уведомление об обратном выкупе',
    QI_CONFIRM: `Заявление о признании Квалифицированным инвестором`,
    QI_NOT_QI: `Заверение неквалифицированного инвестора`,
    ENABLE_AUTO_INVEST_STRATEGY_ALFA_BASIC: `Заявление об изменении настроек автоинвестирования`,
    QI_DOCUMENT: `Заявление о квалификации`,
    ENABLE_DEFAULT_AUTO_INVEST_SETTINGS: `Заявление об изменении настроек автоинвестирования`,
    ENABLE_PERSONAL_AUTO_INVEST_SETTINGS: `Заявление об изменении настроек автоинвестирования`,
    DISABLE_AUTO_INVEST: `Заявление об выключение автоинвестирования`,
    ENABLE_AUTO_INVEST_STRATEGY_ALFA_LIQUID: `Заявление об изменении настроек автоинвестирования`,
    ENABLE_AUTO_INVEST_STRATEGY_LIQUID_FAIR_RATE: `Заявление об изменении настроек автоинвестирования`,
    ENABLE_AUTO_INVEST_STRATEGY_FIXED_FAIR_RATE: `Заявление об изменении настроек автоинвестирования`,
    ENABLE_AUTO_INVEST_STRATEGY_WITHOUT_RISK: `Заявление об изменении настроек автоинвестирования`,
    ENABLE_AUTO_INVEST_STRATEGY_CONSERVATIVE:
      'Заявление об изменении настроек автоинвестирования',
    ENABLE_AUTO_INVEST_STRATEGY_AGGRESSIVE:
      'Заявление об изменении настроек автоинвестирования',
    ENABLE_AUTO_INVEST_STRATEGY_ALFA_CONSERVATIVE:
      'Заявление об изменении настроек автоинвестирования',
    UNKNOWN: `Неизвестный`,
    PASSPORT_PAGE_MAIN: `Главная страница паспорта`,
    PASSPORT_PAGE_REGISTRATION: `Страница паспорта с регистрацией`,
    RECEIPT: `Чек`,
    SPECIAL_INVESTOR_LOANS: `Документ займа ПИФа`,
    SPECIAL_INVESTOR_CESSION: `Документ цессии ПИФа`,
    CESSION_OFFER_PORTFOLIO_SALE_WITH_DISCOUNT: `Оферта на продажу портфеля с дисконтом`,
  },
  thirdPartyServicesData: {
    telegramBotStartLink: 'https://t.me/PotokInfoBot?start=',
  },
  fetchRetryOptions: {
    retries: 7,
    delays: [3000, 5000, 7000, 9000, 11000, 13000, 15000],
    statuses: [502, 503],
  },
  eventLogTypes: {
    projectAcceptance: '8acff104-8ac5-41ad-8279-5081498ee1b4', // Заём выдан
    projectCancel: '06fd8578-44a1-4ea1-ae37-a9931de48be5', // Заём отменен с возвратом средств
    projectPayoff: 'b5ef5b80-5908-423c-9218-d12d4ee09e6f', // Заём возвращен
    projectOverdue: '4fb5938c-3630-4b05-812c-fae336163d84', // Заём просрочен
    projectPartialPayoff: '788f2688-e9ae-4020-aa52-10eafdd7adfb', // Заём частично возвращен
    projectPartialFinalPayoff: '7110f45b-33b1-4959-9b85-18bc4642b5c5', // Заём полностью возвращен
    projectCession1st: 'd3d0ff48-28ca-4131-9368-3172b5287623', // Старт цессии
    projectCession2nd: '33205f76-bee0-4358-800d-4f50c9de384e', // Цессия 105
    projectCessionFixed: '33ed067e-4195-42c0-b975-423c899efd21', // Цессия - фиксирование
    projectCessionPartialPayoff: '453d3866-a064-401a-bd64-7b1c715479b1', // Цессия - частичное гашение
    projectCessionFinalPayoff: '1e86cd03-53c5-449f-a932-2c1c0a4056cb', // Цессия - полное гашение
    projectForceOverdue: '3407b527-50b3-4cf3-812b-b44d976c47d3', // Принудительная просрочка
    projectCameOutOverdue: '4bf1f45b-ceec-40da-9456-d62b9207f012', // Выход из мягкой просрочки - закрыты все этапы
    projectStageOverdue: '90bc118e-60c7-4a39-944b-346a7bc223b9', // Просрочка этапа графика
    projectPartialPayment: '6e91d740-d37e-4dc9-9f93-63ecae8dd70a', // Частичное досрочное погашение
    projectPartialPaymentWithOverdue: 'a2d4bd2f-3a4d-4ae1-b58e-e756ea022bc7', // Частичное гашение при дефолте
    projectAllPaymentWithOverdue: 'f33fb59c-a56b-498f-8d47-a871c6603655', // Окончательное гашение при дефолте
  },
  externalLinks: {
    taxesFaq: 'https://new.potok.digital/docs/taxes/potok_taxeshelp_faq.pdf',
    modulbankRef: 'https://my.modulbank.ru/#/signup?utm_source=potok',
    overduePolicy:
      'https://new.potok.digital/docs/potok-overdue-rules-2019_05_30.pdf',
    underwritingPolicy:
      'https://new.potok.digital/docs/potok_underwriting_terms_2019_09_09.pdf',
  },
  overdueChecklistType: {
    overdue: '144f787a-58f2-406f-bccd-d2bd5521fd59',
    cession: 'b8d651d4-1dd3-4e73-be50-4347e52acc23',
    partialPayoff: 'e660751c-5aae-4759-9c3c-c69e1a045b08',
    finalPayoff: 'b150c657-8aa0-4514-8c45-7ed5fda70c18',
    informLetter: '3cd14565-74be-45de-adaf-85db8d5b5fce',
    disclosure: '622f8297-0a63-40c4-9b61-f7585ac309d7',
    custom: 'e49d4949-b495-45c6-b377-e421df6933ab',
    preTrialApplication: '8a3b95d9-6746-4aa4-b8c0-7ac4c52f657e',
    courtApplication: '3a6fc3d2-8ddc-4f06-a771-351afea16f8d',
    courtHearing: '8b31d06c-7a67-44c1-94cc-a0e9ff0fbef6',
    courtDecision: '52c7171e-5e6e-4e61-88bc-2b5027d38152',
    receivingOrder: 'e114a2b0-ed26-4ef8-af74-fe24404c2a7b',
    bkiInform: '20a9657e-e9c6-420e-a383-1afaa0ad290a',
    fedResourceInform: 'bb990b37-4224-4241-a841-d101b67a60b2',
  },
  moduleBank: {
    bik: '044525092',
  },
  advSources: {
    module: '07f34640-952f-4b9a-a373-4bf1b55be766',
    familiar: 'a2292463-0c09-4f12-a9a2-497ccd07faf6',
    advertising: '454548d7-74a9-4962-9149-c23429618f40',
    article: '05a1e503-8177-4fcc-9fe5-039663eb4822',
    video: '90ca59fa-941b-4949-bbfe-11a109425475',
    telegram: '8bdce33c-2d4a-40cb-ad9c-0554d126b357',
    another: '40c7cffe-5fce-4cb1-b3c8-62c170fc8bf8',
  },
  probationType: {
    rnp: '5eb20f8a-77b5-4dbb-9157-9eac5c49d3e6',
    contractEnd: 'c2575bf6-3580-4839-9ba7-78a0153e0171',
    overdue: 'c898c3a6-57e0-4904-8096-6ba2511b34f3',
  },
  contractStatus: {
    notPaid: 'f20e61f1-c72d-4b41-8be4-af20053a35fe',
    partiallyPaid: 'add40d09-681b-495d-9c13-7e4025c35003',
    dissolved: '8a4a7f72-69fa-4628-99e0-e348a07c4155',
    performed: 'ca65941d-6f73-4ed7-b9fe-980494130e7f',
    paid: 'ca65941d-6f73-4ed7-b9fe-980494130e7f', // the same as 'performed' due to renaming
    noInfo: 'c8d776d7-821f-4380-898b-1ddbb133ce78',
    sufficientPaid: '89d1b408-0da3-4b45-810e-58adbe658a8c',
  },
  passportStatus: {
    EMPTY: 'EMPTY',
    PENDING: 'PENDING',
    REJECTED: 'REJECTED',
    APPROVED: 'APPROVED',
  },
  accountType: {
    personalAccount: {
      investmentFree: 'e6abb856-b308-49f8-a816-a04fdb1a9877',
      investmentReservedFree: 'c3decb8e-0513-4438-9388-ef6f36898706',
      investorPlatformDebt: '640a3ae2-2972-4c18-9f8a-ebbb9a2cbc47',
      borrowerFree: 'b166de90-09a2-45d7-a98e-b165c6cab2f2',
      borrowerPlatformDebt: '290c0e77-a1e1-4f14-9f83-f5b01112909e',
      platformCession: 'fe7d8a7d-1a98-449c-9a32-d39c544155cd',
      project: {
        free: '87bcf55b-c60e-4abd-aa63-75742d2d9c2a',
        transactions: '0d440667-05e3-41f9-a57d-c4fef172cccd',
      },
    },
  },
  okato: {
    1: 'Алтайский край',
    10: 'Амурская область',
    11: 'Архангельская область',
    12: 'Астраханская область',
    14: 'Белгородская область',
    15: 'Брянская область',
    17: 'Владимирская область',
    18: 'Волгоградская область',
    19: 'Вологодская область',
    20: 'Воронежская область',
    45: 'г. Москва столица Российской Федерации город федерального значения',
    40: 'г. Санкт-Петербург. город федерального значения',
    99: 'Еврейская автономная область',
    24: 'Ивановская область',
    25: 'Иркутская область',
    83: 'Кабардино-Балкарская Республика',
    27: 'Калининградская область',
    29: 'Калужская область',
    30: 'Камчатский край',
    91: 'Карачаево-Черкесская Республика',
    32: 'Кемеровская область',
    33: 'Кировская область',
    34: 'Костромская область',
    3: 'Краснодарский край',
    4: 'Красноярский край',
    37: 'Курганская область',
    38: 'Курская область',
    41: 'Ленинградская область',
    42: 'Липецкая область',
    44: 'Магаданская область',
    46: 'Московская область',
    47: 'Мурманская область',
    22: 'Нижегородская область',
    49: 'Новгородская область',
    50: 'Новосибирская область',
    52: 'Омская область',
    53: 'Оренбургская область',
    54: 'Орловская область',
    56: 'Пензенская область',
    57: 'Пермский край',
    5: 'Приморский край',
    58: 'Псковская область',
    79: 'Республика Адыгея (Адыгея)',
    84: 'Республика Алтай',
    80: 'Республика Башкортостан',
    81: 'Республика Бурятия',
    82: 'Республика Дагестан',
    26: 'Республика Ингушетия',
    85: 'Республика Калмыкия',
    86: 'Республика Карелия',
    87: 'Республика Коми',
    88: 'Республика Марий Эл',
    89: 'Республика Мордовия',
    98: 'Республика Саха (Якутия)',
    90: 'Республика Северная Осетия-Алания',
    92: 'Республика Татарстан (Татарстан)',
    93: 'Республика Тыва',
    95: 'Республика Хакасия',
    60: 'Ростовская область',
    61: 'Рязанская область',
    36: 'Самарская область',
    63: 'Саратовская область',
    64: 'Сахалинская область',
    65: 'Свердловская область',
    66: 'Смоленская область',
    7: 'Ставропольский край',
    68: 'Тамбовская область',
    28: 'Тверская область',
    69: 'Томская область',
    70: 'Тульская область',
    71: 'Тюменская область',
    94: 'Удмуртская Республика',
    73: 'Ульяновская область',
    8: 'Хабаровский край',
    75: 'Челябинская область',
    96: 'Чеченская Республика',
    76: 'Читинская область',
    97: 'Чувашская Республика - Чувашия',
    77: 'Чукотский автономный округ',
    78: 'Ярославская область',
  },
  receiptStatus: {
    NEW: 'NEW', // новый сформированный чек в очереди на отправку
    PENDING: 'PENDING', // был отправлен в orange, но ответ по нему еще не поступил или он поставлен в очередь в сервисе, но еще не обработан
    ACCEPT: 'ACCEPT', // пришел ответ от сервиса что с чеком сформирован и отправлен
    TIMEOUT: 'TIMEOUT', // пришел ответ от сервиса что чек не сформирован
    ERROR_CREATE: 'ERROR_CREATE', // пришел ответ об ошибке в данных или подпись не прошла проверку
    ERROR_STATUS: 'ERROR_STATUS', // пришел ответ об ошибке в данных или подпись не прошла проверку
  },
  qiStatus: {
    EMPTY: 'EMPTY',
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
    NOT_QI: 'NOT_QI',
  },
  investmentsAmount: {
    MIN: 400,
    LOAN_RAISED_FUNDS_TO_CANCEL_MIN: 800,
  },
  OLD_SYSTEM_PROFIT: 407706623,
  depositMoneyTrackerTypes: {
    refill: 'refill',
    investFromLoanDescription: 'invest_from_loan_description',
    investFromBorrowerDescription: 'invest_from_borrower_description',
    investFromDashboard: 'investFromDashboard',
  },
  textsByLoanType: {
    tender: 'госзаказ',
    tender_no_purpose: 'госзаказ',
    factoring: 'факторинг',
    hidden_factoring: 'факторинг',
    main: 'развитие бизнеса',
    akbars: 'развитие бизнеса',
    b2b: 'развитие бизнеса',
  },
  messages: {
    finishReg:
      'Для добавления лицевого счёта, а также инвестора ИП или ЮЛ нужно дождаться подтверждения паспорта и пополнить счёт.',
  },
  scheduleTypes: {
    ordinary: 'на первоначальную сумму',
    start_without_od: 'на первоначальную сумму',
    annuitet: 'на остаток основного долга',
    annuitet_delayed_od: 'на остаток основного долга',
    annuitet_last_period_od: 'на остаток основного долга',
    testType: 'тест',
    full_bullet: '',
  },
  investmentStatusTitle: {
    reserved: 'зарезервирована',
    active: 'активна',
    refunded: 'погашена',
    cession: 'дефолт',
  },
  strategesTitle: {
    without_risk: 'без риска',
    fixed_fair_rate: 'базовая',
    liquid_fair_rate: 'ликвидная',
    alfa_liquid: 'альфа — ликвидная',
    alfa_basic: 'альфа — базовая',
    default_borrower_distribution: 'оптимальная',
    personal_borrower_distribution: 'настраиваемая',
    alfa_conservative: 'альфа — консервативная',
    conservative: 'консервативная',
    aggressive: 'агрессивная',
  },
  strategiesServerNames: {
    withoutRisk: 'without_risk',
    fixedFairRate: 'fixed_fair_rate',
    liquidFairRate: 'liquid_fair_rate',
    alfaLiquid: 'alfa_liquid',
    alfaBasic: 'alfa_basic',
    defaultBorrowerDistribution: 'default_borrower_distribution',
    personalBorrowerDistribution: 'personal_borrower_distribution',
    alfaConservative: 'alfa_conservative',
    conservative: 'conservative',
    aggressive: 'aggressive',
  },
  investmentPurchaseDealTypes: {
    normal: 'normal',
    withFutureBuyback: 'with_future_buyback',
    boughtBack: 'bought_back',
    purchaseDefault: 'purchase_default',
    fixedFairRate: 'fixed_fair_rate',
    liquidFairRate: 'liquid_fair_rate',
    alfaLiquid: 'alfa_liquid',
    conservative: 'conservative',
    aggressive: 'aggressive',
    alfaConservative: 'alfa_conservative',
  },
  STRATEGY_SECOND_MARKET: [
    'without_risk',
    'conservative',
    'alfa_conservative',
    'fixed_fair_rate',
    'aggressive',
  ],
};
