<template>
  <div class="new-investments-sell-list-mobile mt-6">
    <div class="new-investments-sell-list-select-status">
      <CSelect v-model="status" :items="statusList" />
    </div>

    <template v-if="isLoading">
      <Loader class="mt-6" />
    </template>

    <template v-if="!isLoading">
      <div class="new-investments-sell-list-container potok-text-body-2 mt-5">
        <div
          v-for="(item, index) in items"
          :key="index"
          class="new-investments-sell-list-item mb-5"
        >
          <div
            class="new-investments-sell-list-item-header mb-7"
            @click="toggleListItem(item.id)"
          >
            <div
              class="new-investments-sell-list-item-header-left potok-text-h2"
            >
              {{ item.number }}

              <div
                class="ml-2"
                :class="{
                  'sell-list-arrow-down': !item.isActiveItem,
                  'sell-list-arrow-up': item.isActiveItem,
                }"
              />
            </div>

            <new-investments-sell-list-btn-status
              :status="item.status"
              :comment="item.comment"
            />
          </div>

          <div
            v-if="item.isActiveItem"
            class="new-investments-sell-list-item-content mb-3"
          >
            <div
              v-for="(contentItem, i) in contentList"
              :key="i"
              class="new-investments-sell-list-item-content-row"
            >
              <span class="new-investments-sell-list-item-content-title">
                {{ contentItem.title }}
              </span>
              <span class="potok-text-body-1 mt-1">
                {{ contentItem.contentHandle(item[contentItem.link]) }}
              </span>
            </div>
          </div>

          <button
            class="new-investments-sell-list-item-btn potok-text-body-1"
            @click="toSellListItem(item.id)"
          >
            перейти в заявку
          </button>
        </div>
      </div>
    </template>
  </div>

  <PaginationMobile
    v-if="!isLoading && props.items.length"
    v-model:current-page="currentPage"
    v-model:selected-page-range="selectedPageRange"
    :is-loading="props.isLoadingMore"
    :total-items-count="props.itemsCount"
    class="mt-2"
  />
</template>

<script setup>
import { ref, watch } from 'vue';
import { CSelect, Loader } from 'potok-uikit';
import PaginationMobile from '@/components/_generic/pagination/paginationMobile.vue';
import { useRouter } from 'vue-router';
import { formatDate, formatCurrency } from '@/utils/commonUtils/libUtils';
import NewInvestmentsSellListBtnStatus from '@/components/Investments/NewInvestmentsSellList/NewInvestmentsSellListBtnStatus.vue';

const props = defineProps([
  'items',
  'isLoading',
  'isLoadingMore',
  'itemsCount',
]);
const emits = defineEmits(['changeStatus', 'toggleItem', 'loadMoreList']);

const router = useRouter();

const selectedPageRange = ref(20);
const currentPage = ref(1);

const status = ref('all');
const statusList = [
  {
    value: 'all',
    title: 'все заявки',
  },
  {
    value: 'confirmed',
    title: 'Активна',
  },
  {
    value: 'approved',
    title: 'Обрабатывается',
  },
  {
    value: 'canceled',
    title: 'Отменена',
  },
  {
    value: 'rejected',
    title: 'Отклонена',
  },
  {
    value: 'completed',
    title: 'Реализована',
  },
];

const formatDateHandle = (date) => {
  return formatDate(date, 'dd.MM.yyyy HH:mm', 'Europe/Moscow');
};
const formatCurrencyHandle = (currency) => {
  return currency ? `${formatCurrency(currency, 0)} ₽` : 'нет данных';
};
const formatPercentsHandle = (currency) => {
  return `${currency}%`;
};

const contentList = [
  {
    link: 'createdAt',
    title: 'дата создания',
    contentHandle: formatDateHandle,
  },
  {
    link: 'requestDueAt',
    title: 'срок действия',
    contentHandle: formatDateHandle,
  },
  {
    link: 'mainDebtAmount',
    title: 'од на момент создания',
    contentHandle: formatCurrencyHandle,
  },
  {
    link: 'limitAmount',
    title: 'лимит',
    contentHandle: formatCurrencyHandle,
  },
  {
    link: 'mainDebtAmountAtCompleted',
    title: 'од на момент выкупа',
    contentHandle: formatCurrencyHandle,
  },
  {
    link: 'discountPercentage',
    title: 'дисконт',
    contentHandle: formatPercentsHandle,
  },
  {
    link: 'soldByAmount',
    title: 'сумма выкупа',
    contentHandle: formatCurrencyHandle,
  },
];

const changeStatus = () => {
  emits('changeStatus', {
    status: status.value,
    selectedPageRange: selectedPageRange.value,
    currentPage: currentPage.value,
  });
};
const loadMoreList = () => {
  emits('loadMoreList', {
    status: status.value,
    selectedPageRange: selectedPageRange.value,
    currentPage: currentPage.value,
  });
};

const toSellListItem = (id) => {
  router.push({
    name: 'sellListItem',
    params: { id },
  });
};

const toggleListItem = (id) => {
  emits('toggleItem', id);
};

watch(status, changeStatus);
watch(currentPage, loadMoreList);
</script>

<style lang="scss" scoped>
.new-investments-sell-list-select-status {
  width: 100%;
}
.new-investments-sell-list-item {
  width: 100%;
  background-color: $white;
  border-radius: 14px;
  padding: 20px 14px 20px 14px;
}
.new-investments-sell-list-item-header {
  display: flex;
  justify-content: space-between;
}
.new-investments-sell-list-item-header-left {
  display: flex;
  align-items: center;
}
.new-investments-sell-list-item-btn {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: transparent;
  border: 1px solid $breakwater;
}
.new-investments-sell-list-item-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.new-investments-sell-list-item-content-row {
  display: flex;
  flex-direction: column;
  padding: 14px 0;
  border-bottom: 1px solid $dividers;
  &:first-child {
    padding: 0 0 14px 0;
  }
  &:last-child {
    border-bottom: none;
  }
}
.new-investments-sell-list-item-content-title {
  color: $grey;
}

.sell-list-arrow-down {
  width: 14px;
  height: 14px;
  background: url('@/assets/img/icons/investments/sell-list-arrow.svg')
    no-repeat;
  background-size: contain;
}

.sell-list-arrow-up {
  width: 14px;
  height: 14px;
  background: url('@/assets/img/icons/investments/sell-list-arrow.svg')
    no-repeat;
  background-size: contain;
  transform: rotate(180deg);
}
</style>
