import { ref } from 'vue';

import { useStateStore } from '@/store/stateStore';
import { decimal } from '@/utils/commonUtils/utils';

const sortingOptionsDefault = [
  {
    title: 'По умолчанию',
    value: 'default',
  },
  {
    title: 'По типу займа',
    value: 'by_type',
  },
  {
    title: 'Дата — по убыванию',
    value: 'date_minus',
  },
  {
    title: 'Дата — по возрастанию',
    value: 'date_plus',
  },
  {
    title: 'Ставка — по убыванию',
    value: 'loan_rate_minus',
  },
  {
    title: 'Ставка — по возрастанию',
    value: 'loan_rate_plus',
  },
  {
    title: 'Остаток — по убыванию',
    value: 'money_minus',
  },
  {
    title: 'Остаток — по возрастанию',
    value: 'money_plus',
  },
];

export default function loansItemsSortComposable() {
  const selectedSortingOptions = ref('default');
  const sortingOptions = ref(sortingOptionsDefault);

  const store = useStateStore();

  const onChangeSortingOptions = (value) => {
    selectedSortingOptions.value = value;
    store.setInvestorSortingPrefecences(value);
  };

  const updateSortingOptions = (value) => {
    selectedSortingOptions.value = value;
    store.setInvestorSortingPrefecences(value);
  };

  const sortItems = (items) => {
    if (
      !selectedSortingOptions.value ||
      selectedSortingOptions.value === 'default'
    ) {
      return items;
    }

    if (selectedSortingOptions.value === 'by_type') {
      let result_items = [];
      const sort_options = [
        'factoring',
        'tender',
        'tender_no_purpose',
        'main',
        'high_risk',
      ];

      sort_options.forEach((sort_option) => {
        result_items = [
          ...result_items,
          ...items.filter((i) => {
            if (sort_option === 'high_risk') {
              return i.isManualInvestExplicit === true;
            } else {
              return (
                i.loanType === sort_option && i.isManualInvestExplicit === false
              );
            }
          }),
        ];
      });
      result_items = [
        ...result_items,
        ...items.filter((i) => !sort_options.includes(i.loanType)),
      ];
      return result_items;
    }

    return items.sort((a, b) => {
      if (selectedSortingOptions.value === 'date_plus') {
        return new Date(a.fundsStartedAt) - new Date(b.fundsStartedAt);
      }

      if (selectedSortingOptions.value === 'date_minus') {
        return new Date(b.fundsStartedAt) - new Date(a.fundsStartedAt);
      }

      if (selectedSortingOptions.value === 'money_minus') {
        const value_b = decimal(b.raisedAmount).plus(b.pifReservedAmount || 0);
        const value_a = decimal(a.raisedAmount).plus(a.pifReservedAmount || 0);
        return (
          decimal(b.loanAmount).minus(value_b) -
          decimal(a.loanAmount).minus(value_a)
        );
      }
      if (selectedSortingOptions.value === 'money_plus') {
        const value_b = decimal(b.raisedAmount).plus(b.pifReservedAmount || 0);
        const value_a = decimal(a.raisedAmount).plus(a.pifReservedAmount || 0);
        return (
          decimal(a.loanAmount).minus(value_a) -
          decimal(b.loanAmount).minus(value_b)
        );
      }
      if (selectedSortingOptions.value === 'loan_rate_plus') {
        return decimal(a.loanRate) - decimal(b.loanRate);
      }
      if (selectedSortingOptions.value === 'loan_rate_minus') {
        return decimal(b.loanRate) - decimal(a.loanRate);
      }
    });
  };

  const initSortingData = () => {
    if (store.state?.preferences?.invertorPage?.sortingOptions) {
      selectedSortingOptions.value =
        store.state.preferences.invertorPage.sortingOptions;
    }
  };

  return {
    selectedSortingOptions,
    sortingOptions,
    onChangeSortingOptions,
    updateSortingOptions,
    sortItems,
    initSortingData,
  };
}
