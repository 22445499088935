<template>
  <div class="analytics-assets mt-2">
    <div class="analytics-assets-row">
      <AnalyticsAssetsIncome
        :loading="loadingAssetIncome"
        :data="assetsIncome"
        :texts="assetsGeneral?.balanceChange"
        class="assets-container block-container mr-7"
        @update-period="getAssetIncome($event)"
      />
      <AnalyticsAssetsOutcome
        :loading="loadingAssetOutcome"
        :data="assetsOutcome"
        :texts="assetsGeneral?.balanceChange"
        class="assets-container block-container"
        @update-period="getAssetOutcome($event)"
      />
    </div>
    <div class="analytics-assets-row mt-7">
      <AnalyticsAssetsSum
        :loading="loadingAssetSum"
        :data="assetsSum"
        :texts="assetsGeneral?.assetSum"
        class="block-container"
        @update-period="getAssetSum($event)"
      />
    </div>
    <div class="analytics-assets-row mt-7">
      <AnalyticsAssetsProfitable />
    </div>
    <div class="analytics-assets-row mt-7">
      <AnalyticsAssetsReturns
        :loading="loadingAssetReturns"
        :data="assetsReturns"
        :texts="assetsGeneral?.investmentsReturns"
        class="assets-container block-container mr-7"
        @update-period="getAssetReturns($event)"
      />
      <AnalyticsAssetsStructure
        :loading="loadingAssetStructure"
        :data="assetsStructure"
        class="assets-container block-container"
      />
    </div>
    <div class="analytics-assets-row mt-7">
      <AnalyticsAssetsPayments
        :loading="loadingAssetPayments"
        :data="assetsPayments"
        :texts="assetsGeneral?.incomeCalendar"
        class="block-container"
        @update-period="getAssetPayments($event)"
      />
    </div>
    <div class="analytics-assets-row mt-7">
      <!--      <AnalyticsAssetsDefaults-->
      <!--        :loading="loadingAssetDefaults"-->
      <!--        :data="assetsDefaults"-->
      <!--        :texts="assetsGeneral?.defaultsAndRestructures"-->
      <!--        class="assets-container block-container mr-7"-->
      <!--        @update-period="getAssetDefaults($event)"-->
      <!--      />-->
      <AnalyticsAssetsDiversification
        :loading="loadingAssetDiversification"
        :data="assetsDiversification"
        class="assets-container block-container"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';

import AnalyticsAssetsSum from '@/components/Analytics/AnalyticsAssets/AnalyticsAssetsSum.vue';
import AnalyticsAssetsIncome from '@/components/Analytics/AnalyticsAssets/AnalyticsAssetsIncome.vue';
import AnalyticsAssetsProfitable from '@/components/Analytics/AnalyticsAssets/AnalyticsAssetsProfitable.vue';
import AnalyticsAssetsReturns from '@/components/Analytics/AnalyticsAssets/AnalyticsAssetsReturns.vue';
//import AnalyticsAssetsDefaults from '@/components/Analytics/AnalyticsAssets/AnalyticsAssetsDefaults.vue';
import AnalyticsAssetsStructure from '@/components/Analytics/AnalyticsAssets/AnalyticsAssetsStructure.vue';
import AnalyticsAssetsDiversification from '@/components/Analytics/AnalyticsAssets/AnalyticsAssetsDiversification.vue';
import AnalyticsAssetsPayments from '@/components/Analytics/AnalyticsAssets/AnalyticsAssetsPayments.vue';

import server from '@/server';
import { useStateStore } from '@/store/stateStore';
import AnalyticsAssetsOutcome from "@/components/Analytics/AnalyticsAssets/AnalyticsAssetsOutcome";

const store = useStateStore();

const loadingAssetGeneral = ref(true);
const loadingAssetSum = ref(true);
const loadingAssetIncome = ref(true);
const loadingAssetOutcome = ref(true);
const loadingAssetReturns = ref(true);
const loadingAssetDefaults = ref(true);
const loadingAssetStructure = ref(true);
const loadingAssetDiversification = ref(true);
const loadingAssetPayments = ref(true);

const assetsGeneral = ref(null);
const assetsSum = ref(null);
const assetsIncome = ref(null);
const assetsOutcome = ref(null);
const assetsReturns = ref(null);
const assetsDefaults = ref(null);
const assetsStructure = ref(null);
const assetsDiversification = ref(null);
const assetsPayments = ref(null);

const selectedCompanyId = computed(() => store.selectedCompanyId);

const getAssetGeneral = () => {
  loadingAssetGeneral.value = true;

  const query = {
    companyId: selectedCompanyId.value,
  };

  return server.getAssetGeneral
    .send(query)
    .pipe(onFetchAssetGeneralSuccess, () => {
      loadingAssetGeneral.value = false;
    })
    .exec();
};
const onFetchAssetGeneralSuccess = (obj) => {
  loadingAssetGeneral.value = false;
  assetsGeneral.value = obj.data || {};
};

const getAssetSum = (period = 'month') => {
  loadingAssetSum.value = true;

  const query = {
    companyId: selectedCompanyId.value,
    groupBy: period,
  };

  return server.getAssetSum
    .send(query)
    .pipe(onFetchAssetSumSuccess, () => {
      loadingAssetSum.value = false;
    })
    .exec();
};
const onFetchAssetSumSuccess = (obj) => {
  loadingAssetSum.value = false;
  assetsSum.value = obj.data.data;
};

const getAssetReturns = (period = 'month') => {
  loadingAssetReturns.value = true;

  const query = {
    companyId: selectedCompanyId.value,
    groupBy: period,
  };

  return server.getAssetReturns
    .send(query)
    .pipe(onFetchAssetReturnsSuccess, () => {
      loadingAssetReturns.value = false;
    })
    .exec();
};
const onFetchAssetReturnsSuccess = (obj) => {
  loadingAssetReturns.value = false;
  assetsReturns.value = obj.data;
};

const getAssetIncome = (period = 'month') => {
  loadingAssetIncome.value = true;

  const query = {
    companyId: selectedCompanyId.value,
    groupBy: period,
  };

  return server.getAssetBalance
    .send(query)
    .pipe(onFetchAssetIncomeSuccess, () => {
      loadingAssetIncome.value = false;
    })
    .exec();
};
const onFetchAssetIncomeSuccess = (obj) => {
  loadingAssetIncome.value = false;
  assetsIncome.value = obj.data;
};

const getAssetOutcome = (period = 'month') => {
  loadingAssetOutcome.value = true;

  const query = {
    companyId: selectedCompanyId.value,
    groupBy: period,
  };

  return server.getAssetBalance
    .send(query)
    .pipe(onFetchAssetOutcomeSuccess, () => {
      loadingAssetOutcome.value = false;
    })
    .exec();
};
const onFetchAssetOutcomeSuccess = (obj) => {
  loadingAssetOutcome.value = false;
  assetsOutcome.value = obj.data;
};

const getAssetDefaults = (period = 'month') => {
  loadingAssetDefaults.value = true;

  const query = {
    companyId: selectedCompanyId.value,
    groupBy: period,
  };

  return server.getAssetDefaults
    .send(query)
    .pipe(onFetchAssetDefaultsSuccess, () => {
      loadingAssetDefaults.value = false;
    })
    .exec();
};
const onFetchAssetDefaultsSuccess = (obj) => {
  loadingAssetDefaults.value = false;
  assetsDefaults.value = obj.data;
};

const getAssetStructure = () => {
  loadingAssetStructure.value = true;

  const query = {
    companyId: selectedCompanyId.value,
  };

  return server.getAssetStructure
    .send(query)
    .pipe(onFetchAssetStructureSuccess, () => {
      loadingAssetStructure.value = false;
    })
    .exec();
};
const onFetchAssetStructureSuccess = (obj) => {
  loadingAssetStructure.value = false;
  assetsStructure.value = obj.data;
};

const getAssetDiversification = () => {
  loadingAssetDiversification.value = true;

  const query = {
    companyId: selectedCompanyId.value,
  };

  return server.getAssetDiversification
    .send(query)
    .pipe(onFetchAssetDiversificationSuccess, () => {
      loadingAssetDiversification.value = false;
    })
    .exec();
};
const onFetchAssetDiversificationSuccess = (obj) => {
  loadingAssetDiversification.value = false;
  assetsDiversification.value = obj.data;
};

const getAssetPayments = (period = 'month') => {
  loadingAssetPayments.value = true;

  const query = {
    period: period,
  };

  return server.postCompanySchedulePayment
    .send(query, { params: { companyId: selectedCompanyId.value } })
    .pipe(onFetchAssetPaymentsSuccess, () => {
      loadingAssetPayments.value = false;
    })
    .exec();
};
const onFetchAssetPaymentsSuccess = (obj) => {
  loadingAssetPayments.value = false;
  const data = obj.data || {};
  assetsPayments.value = data;
};
const loadData = () => {
  const promises = [
    getAssetGeneral(),
    getAssetSum(),
    getAssetIncome(),
    getAssetOutcome(),
    getAssetReturns(),
    getAssetDefaults(),
    getAssetStructure(),
    getAssetDiversification(),
  ];

  return Promise.allSettled(promises);
};

watch(
  () => {
    return selectedCompanyId.value;
  },
  () => {
    loadData();
  },
  {
    immediate: true,
  },
);

getAssetPayments();
</script>

<style lang="scss" scoped>
.analytics-assets-row {
  display: flex;

  @media (max-width: $size_991) {
    flex-direction: column;
  }
}

.assets-container {
  width: 50%;

  @media (max-width: $size_991) {
    width: 100%;
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }
  }
}

:deep(.graph-tooltip-container) {
  min-width: 330px;
  width: max-content;
  background: $white;
  border-radius: 8px;
  pointer-events: none;
  position: absolute;
  transition: all 0.1s ease;
  box-shadow: 0 2px 22px 0 rgba(13, 39, 65, 0.12);
  z-index: 2;

  @media (max-width: $size_991) {
    min-width: 220px;
  }
}

:deep(.graph-tooltip-header) {
  height: 44px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
  padding: 12px 20px 12px 20px;
  background: rgba(232, 235, 238, 1);

  @media (max-width: $size_991) {
    padding: 8px 8px 8px 8px;
    height: 30px;
  }
}

:deep(.graph-tooltip-header-text) {
  position: relative;
  text-transform: uppercase;
  top: 1px;
}

:deep(.graph-tooltip-item-circle) {
  position: relative;
  top: 1px;
  border-radius: 50%;
  border-width: 0;
  margin-right: 10px;
  height: 10px;
  width: 10px;
  display: inline-block;

  @media (max-width: $size_991) {
    margin-right: 5px;
  }
}

:deep(.graph-tooltip-footer-circle) {
  background: $dividers;
  border-color: $dividers;
}

:deep(.graph-tooltip-item) {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:first-child {
    margin-top: 0;
  }
}

:deep(.graph-tooltip-item-left) {
  display: flex;
  align-items: center;
  color: $grey;
  margin-right: 10px;
}

:deep(.graph-tooltip-body) {
  padding: 20px 20px 12px 20px;

  @media (max-width: $size_991) {
    padding: 8px 8px 8px 8px;
  }
}

:deep(.graph-tooltip-strip) {
  display: flex;
  height: 7px;
  width: 100%;
  border-radius: 9.5px;
  margin-bottom: 20px;
}

:deep(.graph-tooltip-strip-item) {
  &:first-child {
    border-top-left-radius: 9.5px;
    border-bottom-left-radius: 9.5px;
  }
  &:last-child {
    border-top-right-radius: 9.5px;
    border-bottom-right-radius: 9.5px;
  }
}

:deep(.graph-tooltip-footer-content) {
  display: flex;
  justify-content: space-between;
  padding: 14px 20px 16px 20px;

  @media (max-width: $size_991) {
    padding: 8px 8px 8px 8px;
  }
}

:deep(.graph-tooltip-text-bold) {
  font-weight: 500;
}

:deep(.graph-tooltip-mobile-value) {
  @media (max-width: $size_991) {
    font-size: 11px;
  }
}
</style>
