<template>
  <div class="balance_item">
    <div class="balance_title_wrapper">
      <i class="icon_myinvestment_trendup"></i>
      <div class="balance_tag">
        <div>
          <div style="display: inline-block" @click.prevent="">
            <VDropdown placement="bottom-start">
              <button>
                +
                {{ formatCurrency(promoIncome, 0) }}
                ₽ <span class="text_bonus">бонусы</span>
              </button>
              <template #popper>
                <div class="popup">
                  <div v-close-popper class="popup-close"></div>
                  <div class="popup-text">
                    Начисления по акциям, за весь период участия.*
                    <br />
                    *для инвесторов-физических лиц суммы указаны за вычетом
                    НДФЛ.
                  </div>
                </div>
              </template>
            </VDropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="balance_title">Полученный доход</div>
    <template v-if="loadingCompanyPromoIncome">
      <Loader style="margin-top: 10px" />
    </template>
    <template v-if="!loadingCompanyPromoIncome">
      <div class="balance_value">
        <div style="display: inline-block" @click.prevent="">
          <VDropdown placement="bottom-start">
            <MoneyValue
              :value="statIncome"
              :rounding="false"
              color="#04CCD9"
              comma-color="#04CCD9"
            />
            <template #popper>
              <div class="popup">
                <div v-close-popper class="popup-close"></div>
                <div class="popup-text">
                  Сумма процентов, пени и выплат по цессии, полученная за весь
                  период инвестирования*. Детали вы можете увидеть в
                  <router-link :to="{ name: 'analyticsHistoryOperation' }">
                    истории операций</router-link
                  >.
                  <br />
                  *для инвесторов-физических лиц суммы указаны за вычетом НДФЛ.
                </div>
              </div>
            </template>
          </VDropdown>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import mixins from '@/mixins';
import { formatCurrency } from '@/utils/commonUtils/libUtils';
import { Loader } from 'potok-uikit';
import MoneyValue from '@/components/_generic/MoneyValue.vue';

export default {
  components: {
    MoneyValue,
    Loader,
  },
  mixins: [mixins.common],
  props: ['statIncome', 'promoIncome', 'loadingCompanyPromoIncome'],
  methods: {
    formatCurrency,
  },
};
</script>
<style lang="scss" scoped>
.icon_myinvestment_trendup {
  flex-shrink: 0;
  margin-right: 10px;
}
.text_bonus {
  @media (max-width: $size_991) and (min-width: $size_767) {
    display: none;
  }
}
.balance_title_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.balance_value {
  color: $tiffany;
}
.balance_tag {
  background: rgba(4, 204, 217, 0.1);
  div {
    color: $tiffany;
  }
}
</style>
