<template>
  <div class="clean-profit block_content">
    <div class="item-header">чистая доходность</div>
    <div>
      <div class="item-row item-row-border">
        <div class="tag-icon tag-icon-red"><i class="icon-profit-red"></i></div>
        <div>
          <div class="item-name">
            {{ props.data?.data?.withheldTax?.description }}
          </div>
          <div class="item-value">
            <MoneyValue
              :value="data?.data?.withheldTax?.value"
              :rounding="false"
            />
          </div>
        </div>
      </div>
      <div class="item-row item-row-border">
        <div class="tag-icon tag-icon-grey"><i class="icon-chart"></i></div>
        <div>
          <div class="item-name">итого после ндфл</div>
          <div class="item-value">
            <MoneyValue
              :value="data?.data?.netTotal?.value"
              :rounding="false"
            />
          </div>
        </div>
      </div>
      <div class="item-row item-row-border">
        <div class="tag-icon tag-icon-grey"><i class="icon-bag"></i></div>
        <div>
          <div class="item-name">
            {{ props.data?.data?.portfolioProfitabilityNet?.description }}
          </div>
          <div class="item-value">
            {{ data?.data?.portfolioProfitabilityNet?.value }}
            % годовых
          </div>
        </div>
      </div>
      <div class="item-row">
        <div class="tag-icon tag-icon-grey">
          <PotokImg
            :src="imageConstants.analytics.health"
            height="18"
            width="18"
          />
        </div>
        <div>
          <div class="item-name">
            {{ data?.data?.indexWithoutNdfl?.description }}
          </div>
          <div class="item-value flex-value potok-text-body-1-bold">
            {{ data?.data?.indexWithoutNdfl?.value }} %
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import PotokImg from '@/components/_generic/PotokImg.vue';
import { formatCurrency } from '@/utils/commonUtils/utils';
import imageConstants from '@/constants/imageConstants';
import MoneyValue from '@/components/_generic/MoneyValue.vue'

const props = defineProps({
  data: {
    type: Object,
    required: false,
  },
});
</script>
<style lang="scss" scoped>
.clean-profit {
  @media (max-width: $size_767) {
    min-height: 460px;
  }
}
.block_content {
  width: 100%;
  padding: 20px 20px 0 20px;
  @media (max-width: $size_767) {
    padding: 20px 0 0 0;
  }
}
.icon-profit-red {
  background-image: url('@img/icons/analytics/assets/discount-circle-red.svg');
  width: 18px;
  height: 18px;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
}
.icon-chart {
  background-image: url('@img/icons/analytics/assets/chart.svg');
  width: 18px;
  height: 18px;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
}
.profitable-value-circle {
  height: 4px;
  width: 4px;
  background: $deep_dive;
  border-radius: 50%;
  margin-left: 8px;
  margin-right: 8px;
}
.flex-value {
  display: flex;
  align-items: center;
}
</style>
