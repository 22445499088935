<template>
  <div class="result block_content">
    <div class="item-header">результат</div>
    <div>
      <div class="item-row item-row-border">
        <div class="tag-icon tag-icon-green">
          <PotokImg
            :src="imageConstants.analytics.discountCircle"
            height="18"
            width="18"
          />
        </div>
        <div>
          <div class="item-name-container">
            <div class="item-name">
              {{ data?.data?.interestWithPenalty?.description }}
            </div>
            <VDropdown
              :placement="'right'"
              :triggers="['click']"
              class="help-dropdown"
            >
              <PotokImg
                :src="imageConstants.attention"
                height="14"
                width="14"
                class="ml-2 item-icon"
              />
              <template #popper>
                <div class="popup">
                  <div class="popup-text">включая бонусный доход</div>
                </div>
              </template>
            </VDropdown>
          </div>
          <div class="item-value">
            +
            <MoneyValue
              :value="data?.data?.interestWithPenalty?.value"
              :rounding="false"
              class="ml-1"
            />
          </div>
        </div>
      </div>
      <div class="item-row item-row-border">
        <div class="tag-icon tag-icon-red">
          <PotokImg
            :src="imageConstants.analytics.redCircleArrayDown"
            height="18"
            width="18"
          />
        </div>
        <div>
          <div class="item-name">
            {{ data?.data?.defaultsMainDebt?.description }}
          </div>
          <div class="item-value">
            <span v-if="data?.data?.defaultsMainDebt?.value > 0">-</span>
            <MoneyValue
              :value="data?.data?.defaultsMainDebt?.value"
              :rounding="false"
              class="ml-1"
            />
          </div>
        </div>
      </div>
      <div class="item-row item-row-border">
        <div class="tag-icon tag-icon-yellow">
          <PotokImg
            :src="imageConstants.analytics.returns"
            height="18"
            width="18"
          />
        </div>
        <div>
          <div class="item-name">
            {{ data?.data?.defaultsIncomeWithPenaltyAndMainDebt?.description }}
          </div>
          <div class="item-value">
            +
            <MoneyValue
              :value="data?.data?.defaultsIncomeWithPenaltyAndMainDebt?.value"
              :rounding="false"
              class="ml-1"
            />
          </div>
        </div>
      </div>
      <div class="item-row-itogo">
        <div class="item-name">итого</div>
        <div class="item-value">
          +
          <MoneyValue
            :value="data?.data?.total?.value"
            :rounding="false"
            class="ml-1"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { toRefs } from 'vue';
import PotokImg from '@/components/_generic/PotokImg.vue';
import imageConstants from '@/constants/imageConstants';
import MoneyValue from '@/components/_generic/MoneyValue.vue'

const props = defineProps({
  data: {
    type: Object,
    required: false,
  },
});

const { data } = toRefs(props);
</script>
<style lang="scss" scoped>
.result {
  @media (max-width: $size_767) {
    min-height: 460px;
  }
}
.block_content {
  width: 100%;
  padding: 20px 20px 0 10px;
  @media (max-width: $size_767) {
    padding: 20px 0 0 0;
  }
}
.item-row-itogo {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
}
.item-name-container {
  display: flex;
  align-items: center;
}
.item-value {
  display: flex;
}
.item-icon {
  position: relative;
  top: 2px;
}
</style>
