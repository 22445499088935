<template>
  <div class="login__content">
    <section class="page container_wrapper">
      <div class="login_title">
        <div>вход</div>
      </div>
      <div v-if="!isOtpMode" class="login_subtitle">
        введите пароль для номера <br />
        <span>{{ decorateFormatPhone(props.rawPhone) }}</span>
      </div>
      <div v-if="isOtpMode" class="login_subtitle login_subtitle_restricted">
        {{ otpMessage }}
      </div>
      <div>
        <div v-if="props.params.isSalesman">
          <input
            v-model="salesmanNumber"
            class="form-control salesman_number_input"
            type="text"
            placeholder="номер лс или номер компании"
          />
        </div>
        <div v-show="!isOtpMode" class="login_actions">
          <div class="login_input_wrapper">
            <FormFieldPasspord
              id="password"
              v-model="rawPassword"
              :readonly="loading"
              placeholder="пароль"
              @update:model-value="onKeyPassword"
              @keyup.enter="onClickEnterPassword()"
            />
            <div v-if="errorMessagePassword" class="potok-color-red">
              {{ errorMessagePassword }}
            </div>
          </div>
          <button
            :disabled="loading"
            type="button"
            class="button button-breakwater button-continue"
            @click="onClickEnterPassword()"
          >
            продолжить
          </button>

          <PotokButton
            text="забыли пароль?"
            size="link"
            theme="link"
            class="button-password-forgot"
            @click="onClickPasswordForgot"
          />

          <PotokButton
            text="назад"
            size="link"
            theme="link"
            class="button-password-back"
            @click="onClickBack"
          />
        </div>
        <div v-show="isOtpMode" class="login_actions">
          <div class="login_input_wrapper">
            <FormFieldPasspord
              id="otpCode"
              v-model="rawOtpCode"
              :readonly="loading"
              :vmaska="localMasks.smsCode"
              placeholder="проверочный код"
              @update:model-value="onKeyOtpCode"
              @keyup.enter="onClickEnterOtpCode()"
            />
            <div v-if="errorMessageOtp" class="potok-color-red">
              {{ errorMessageOtp }}
            </div>
          </div>
          <button
            :disabled="loading"
            type="button"
            class="button button-breakwater button-continue"
            @click="onClickEnterOtpCode()"
          >
            продолжить
          </button>
          <button
            type="button"
            class="button button-text button-password-back"
            @click="onClickBack()"
          >
            назад
          </button>
        </div>
      </div>
    </section>
  </div>
</template>
<script setup>
import { ref, computed, nextTick, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import Countdown from '@/utils/classes/Countdown';
import server from '@/server';
import FormFieldPasspord from '@/components/_generic/forms/FormFieldPasspord.vue';
import { useStateStore } from '@/store/stateStore';
import masks from '@/utils/masks';
import { focusDomElementById, getProp } from '@/utils/commonUtils/utils';

import tracker from '@/tracker';
import {
  convertRawPhoneToLogin,
  decorateFormatPhone,
} from '@/utils/phone/formatPhone';

import { PotokButton } from 'potok-uikit';

const store = useStateStore();
const route = useRoute();
const router = useRouter();

const props = defineProps(['rawPhone', 'params']);
const emit = defineEmits(['changeMode']);

const countdown = new Countdown();
const leftTime = ref(0);

const otpMessage = ref(null);
const loginError = ref(null);
const salesmanNumber = ref(null);

const isUserDataReady = ref(false);

const loading = ref(false);
const rawPassword = ref(null);
const rawOtpCode = ref(null);

const errorMessagePassword = ref(null);
const errorMessageOtp = ref(null);

const isValidPassword = () => {
  if (!rawPassword.value) {
    errorMessagePassword.value = 'Пароль не может быть пустым';
    return false;
  }
  return true;
};

const isValidOtpCode = () => {
  if (!rawOtpCode.value) {
    errorMessageOtp.value = 'Код не может быть пустым';
    return false;
  }
  return true;
};

const onKeyOtpCode = () => {
  errorMessageOtp.value = null;
};
const onKeyPassword = (value) => {
  rawPassword.value = value;
  errorMessagePassword.value = null;
};

const localMasks = computed(() => {
  return {
    smsCode: masks.digits(6),
  };
});

const isOtpMode = computed(() => {
  return !!otpMessage.value;
});

const onClickEnterPassword = () => {
  enterPassword({ phone: props.rawPhone });
};
const onClickPasswordForgot = () => {
  emit('changeMode', {
    component: 'inputRecovery',
  });
};
const onClickBack = () => {
  emit('changeMode', {
    component: 'inputPhone',
  });
};

const onClickEnterOtpCode = () => {
  if (loading.value) {
    return;
  }
  if (!isValidOtpCode()) {
    return;
  }
  const query = {
    login: convertRawPhoneToLogin(props.rawPhone),
    password: rawPassword.value,
    otp: rawOtpCode.value,
  };

  loading.value = true;
  return server.postUsersLoginOtp
    .send(query)
    .pipe(onLoginOtpAsyncSuccess.bind(this, query), () => {
      loading.value = false;
    })
    .exec();
};

const onLoginOtpAsyncSuccess = ({ login }, { data }) => {
  loading.value = false;
  const { token, error } = data;
  if (error) {
    errorMessageOtp.value = error;
    loginError.value = '';
    return;
  }
  doLogin({ login, token });
};

const enterPassword = ({ phone }) => {
  if (loading.value) {
    return;
  }

  if (!isValidPassword()) {
    return;
  }
  const query = {
    login: convertRawPhoneToLogin(phone),
    password: rawPassword.value,
  };
  if (salesmanNumber.value) {
    if (salesmanNumber.value.length < 20) {
      query.accountNumber = parseInt(salesmanNumber.value, 10);
    } else {
      query.companyId = salesmanNumber.value.toLowerCase();
    }
  }
  loading.value = true;
  return server.postUsersLogin
    .send(query)
    .pipe(
      onLoginAsyncSuccess.bind(this, query),
      onLoginAsyncError.bind(this, query),
    )
    .exec();
};

const onLoginAsyncSuccess = ({ login }, { data }) => {
  loading.value = false;
  const { token, error } = data;
  const localOtpMessage = data.otpMessage;
  if (error) {
    otpMessage.value = '';
    errorMessageOtp.value = '';
    loginError.value = error;
    return;
  } else if (localOtpMessage) {
    // Switch to otp mode
    otpMessage.value = localOtpMessage;
    loginError.value = '';
    countdown.stop();
    countdown.reset();
    countdown.start();
    return;
  }

  doLogin({ login, token });
};

const onLoginAsyncError = (_, { data }) => {
  loading.value = false;
  if (!data.message) {
    return;
  }
  if (data.message === 'Internal Server Error') {
    errorMessagePassword.value = 'Ошибка. Попробуйте позже';
  } else {
    errorMessagePassword.value = data.message;
  }
};

const getUsersMeAsync = () => {
  return server.getUsersMe
    .send()
    .pipe(onGetUsersMeAsyncSuccess, () => {
      isUserDataReady.value = true;
    })
    .exec();
};
const onGetUsersMeAsyncSuccess = ({ data: user }) => {
  store.merge({ user });
  isUserDataReady.value = true;

  tracker.queue(tracker.commands.PARAMS, {
    userId: getProp(store.state.user, 'id', ''),
  });
};

const doLogin = async ({ login, token }) => {
  otpMessage.value = null;
  errorMessageOtp.value = null;
  loginError.value = null;
  store.merge({
    dictionaries: {
      companies: [],
    },
    session: {
      login,
      token,
      startSession: new Date().getTime(),
    },
  });

  getUsersMeAsync();
};

const redirectAfterLogin = () => {
  const objRedirect = {
    params: {},
  };

  if (route.query.url) {
    let { url } = route.query;
    if (url.startsWith('/')) {
      url = url.substring(1);
    }
    objRedirect.path = url;
    delete route.query.url;
  } else {
    objRedirect.name = 'main';
  }

  if (route.query.id) {
    objRedirect.params.id = route.query.id;
    delete route.query.id;
  }

  tracker.queue(
    tracker.commands.SEND,
    'Adg_Investory_Shag3_Avtorizacija_uspeshno',
  );

  try {
    objRedirect.query = route.query;
    router.push(objRedirect);
  } catch (e) {
    router.push({
      name: 'main',
      params: {},
    });
  }
};

watch(isUserDataReady, () => {
  if (isUserDataReady.value) {
    redirectAfterLogin();
  }
});

const init = () => {
  nextTick(() => {
    focusDomElementById('password');
  });
  countdown.onTick = (val) => {
    leftTime.value = val;
  };
};
onMounted(() => {
  init();
});
</script>
<style lang="scss" scoped>
.login_title {
  margin-top: 74px;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: $deep_dive;
  width: 100%;
  display: flex;
  justify-content: center;
  div {
    max-width: 688px;
  }
  @media (max-width: $size_767) {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.01em;
    div {
      max-width: 208px;
    }
  }
}
.login_subtitle {
  margin: 15px auto 0 auto;
}
.login_subtitle_restricted {
  max-width: 306px;
}
.login_actions {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.countdown_span {
  margin-top: 8px;
}

.button-password-forgot {
  margin-top: 30px;
}

.button-password-back {
  margin-top: 20px;
}
.salesman_number_input {
  width: 340px;
  margin-top: 20px;
  margin-bottom: -5px;
  padding-left: 20px;
  @media (max-width: $size_767) {
    width: 100%;
  }
}
</style>
