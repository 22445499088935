<template>
  <div class="balance_item">
    <i class="icon_myinvestment_trenddown"></i>
    <div class="balance_title">Дефолт</div>
    <template v-if="isLoading">
      <Loader style="margin-top: 10px" />
    </template>
    <template v-if="!isLoading">
      <div class="balance_value">
        <div style="display: inline-block" @click.prevent="">
          <VDropdown placement="bottom-start">
            <MoneyValue
              :value="overdueAmount"
              :rounding="false"
              color="#EE3D48"
              comma-color="#EE3D48"
            />
            <template #popper>
              <div class="popup">
                <div v-close-popper class="popup-close"></div>
                <div class="popup-text">
                  Сумма остатка основного долга по займам в просрочке более 28
                  дней, за исключением реструктурированных займов.
                </div>
              </div>
            </template>
          </VDropdown>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import mixins from '@/mixins';
import { Loader } from 'potok-uikit';
import MoneyValue from '@/components/_generic/MoneyValue.vue';
import { useStateStore } from '@/store/stateStore';
import { formatCurrency } from '@/utils/commonUtils/libUtils';

export default {
  components: {
    MoneyValue,
    Loader,
  },
  mixins: [mixins.common],
  data() {
    return {
      isLoading: false,
      overdueAmount: 0,
    };
  },
  computed: {
    selectedCompanyId() {
      return useStateStore().selectedCompanyId;
    },
  },
  watch: {
    selectedCompanyId() {
      if (this.selectedCompanyId) {
        this.getCompanyOverdueAsync();
      }
    },
  },
  mounted() {
    if (this.selectedCompanyId) {
      this.getCompanyOverdueAsync();
    }
  },
  methods: {
    formatCurrency,
    getCompanyOverdueAsync() {
      const query = {
        companyIds: this.selectedCompanyId,
      };
      this.isLoading = true;
      return this.server.getUsersCompanyDefaultIncome
        .send(query)
        .pipe(this.onGetCompanyOverdueAsyncSuccess, () => {
          this.isLoading = false;
        })
        .exec();
    },
    onGetCompanyOverdueAsyncSuccess({ data }) {
      this.isLoading = false;
      this.overdueAmount = data.sum || 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.balance_value {
  color: $red;
}
</style>
